import React, { useEffect, useState } from "react";
import getDataFromApi from "../getDataFromApi";
import Form, { utils, withTheme } from "@rjsf/core";
import { Theme } from "@rjsf/bootstrap-4";
import { getCokkie } from "../../views/login/Login";
import { result } from "lodash";
import AlertComponent from "../alert/AlertComponent";
import { Alert } from "reactstrap";

export default function AiModelform(props) {
  const [formData, setFormData] = useState();
//   const [alert, setAlert] = useState("");
  const url = `${window["ENV"].API_URL}/view-api/tasks/models/start/${props.id}`;
  const uiSchema = {};
  const [form, setForm] = useState();
  const registry = utils.getDefaultRegistry();
  const defaultFileWidget = registry.widgets["FileWidget"];
  Theme.widgets["FileWidget"] = defaultFileWidget;
  const Form = withTheme(Theme);
  useEffect(() => {
    getDataFromApi(url, "get")
      .then((r) => {
        if (r === "Contact your organisation admin to add a new user") {
          //   setAlert({
          //     message: "Contact your organisation admin to add a new user",
          //   });
        } else {
          const temp = r;
          temp.json_schema.title = "";
          setForm(temp);
        }
      })

      .catch((e) => {
        // setAlert({ message: e.message, status: "error" });
        // close(!open);
      });
  }, []);


  function formSave(x) {
    let cokkie = getCokkie("jwtToken");
    var myHeaders = new Headers();
    var urlencoded = JSON.stringify(x);
    var requestOptions2 = {
      method: "post",
      headers: myHeaders,
      body: urlencoded,
    };
    myHeaders.append("Authorization", `Bearer ${cokkie}`);
    fetch(
      `${window["ENV"].API_URL}/view-api/tasks/models/start/${props.id}`,
      requestOptions2
    )
      .then((result) => result.json())
      .then((result) => {
        // setAlert(result?.detail);
        props.collpase(result?.detail);
      });

    // console.log(result.detail)
    //   } catch (error) {
    //     window.alert(error.message + " or Internal Server Error");
    //   }
    
  }
  return (
    <>
      {form && (
        <Form
          schema={form?.json_schema}
          uiSchema={form?.ui_schema ?? uiSchema}
          formData={formData}
          onSubmit={({ formData }, e) => {
            e.preventDefault();
            setFormData(formData);
            formSave(formData)
           
          }}
          //   widgets={CustomWidget}
          // showErrorList={false}
        />
      )}
    
    </>
  );
}
