import { useState, useEffect } from "react";
import { getCokkie, logout } from "../views/login/Login";
let error, status;
export default function useFetch(path, method, type) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [fetchNow, setFetchNow] = useState(false);
  const [alert, setAlert] = useState();
  useEffect(() => {
    //used to cancel immediate intermediate requests which results in api performance uplift
    const controller = new AbortController();
    const signal = controller.signal;
    setLoading(true);
    status = error = undefined;
    const cokkie = getCokkie("jwtToken");
    const url = `${window["ENV"].API_URL}/view-api/` + path;
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${cokkie}`);
    myHeaders.append("Cache-Control", "no-cache")
    var requestOptions = {
      method: method ?? "get",
      headers: myHeaders,
      signal,
    };
    fetch(url, requestOptions)
      .then((response) => {
        status = response.status;
        if (response.status === 401) {
          logout();
          throw new Error("User not Authenticated");
        }
        else if(response.status === 403){
         
          throw new Error("🚫 You are unauthorised to use this action");
        }
        // eslint-disable-next-line eqeqeq
        if (response.status == 200 && type === "blob") return response.blob();
        else return response.json();
      })
      .then((result) => {
        error = undefined;
        setData(result);
      })
      .catch((e) => {
       
        setAlert({ message: e.message, status: "error" });
        //close(!open);
        const timeoutId = setTimeout(() => {
          setAlert(null);
          
        }, 2000);

        return () => clearTimeout(timeoutId);
        
      })
      .finally(() => setLoading(false));
    return () => {
      controller.abort();
    };
  }, [method, path, type, fetchNow]);

  function refetch() {
    setFetchNow(!fetchNow);
  }
  function setLoad(value) {
    setLoading(value);
  }

  return { loading, data, error, refetch, status, setLoad, setData ,alert};
}
