import Router from "./Router";
import "bootstrap/dist/css/bootstrap.css";
import "./App.scss"
function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
