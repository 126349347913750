import { HotKeyAction } from "../../data/HotKeyAction";
import { GeneralSelector } from "../../store/selectors/GeneralSelector";

export class BaseContext {
    public static actions: HotKeyAction[] = [];

    public static getActions(): HotKeyAction[] {
        // return GeneralSelector.getHotKeys()
        return this.actions;
    }
}