import { HotKeyAction } from "../../data/HotKeyAction";
import { EditorModel } from "../../staticModels/EditorModel";
import { LabelType } from "../../data/enums/LabelType";
import { EditorData } from "../../data/EditorData";
import { EditorActions } from "../../logic/actions/EditorActions";
import { PolygonRenderEngine } from "../../logic/render/PolygonRenderEngine";
import { PolylineRenderEngine } from "../../logic/render/PolylineRenderEngine";
import { LineRenderEngine } from "../../logic/render/LineRenderEngine";
import { ImageActions } from "../../logic/actions/ImageActions";
import { ViewPortActions } from "../../logic/actions/ViewPortActions";
import { LabelActions } from "../../logic/actions/LabelActions";
import { LabelsSelector } from "../../store/selectors/LabelsSelector";
import { updateImageDragModeStatus } from "../../store/general/actionCreators";
import { GeneralSelector } from "../../store/selectors/GeneralSelector";
import { store } from "../..";
import { updateActiveLabelNameId } from "../../store/labels/actionCreators";
import {
  redo,
  undo,
} from "../../views/EditorView/EditorTopNavigationBar/EditorTopNavigationBar";
import { RectRenderEngine } from "../../logic/render/RectRenderEngine";
var hideAll = true;
export default function editorActionsHelper(hotKeys) {
  if (!hotKeys) {
    hotKeys = {
      "Zoom In": ["Control", "i"],
      "Zoom Out": ["Control", "k"],
      "label 0": ["0"],
      "label 1": ["1"],
      "label 2": ["2"],
      "label 3": ["3"],
      "label 4": ["4"],
      "label 5": ["5"],
      "label 6": ["6"],
      "label 7": ["7"],
      "label 8": ["8"],
      "label 9": ["9"],
      "label 10": ["Alt", "0"],
      "label 11": ["Alt", "1"],
      "label 12": ["Alt", "2"],
      "label 13": ["Alt", "3"],
      "label 14": ["Alt", "4"],
      "label 15": ["Alt", "5"],
      "label 16": ["Alt", "6"],
      "label 17": ["Alt", "7"],
      "label 18": ["Alt", "8"],
      "label 19": ["Alt", "9"],
      "Prev Image": ["ArrowLeft"],
      "Next Image": ["ArrowRight"],
      Pan: ["p"],
      Save: ["Control", "s"],
      Copy: ["Control", "c"],
      Paste: ["Control", "v"],
      Hide: ["Alt", "h"],
      // Hideselected: ["h"],
      Escape: ["Escape"],
      Enter: ["Enter"],
      Delete: ["Delete"],
      Backspace: ["Backspace"],
    };
    localStorage.setItem("hotkeys", JSON.stringify(hotKeys));
  }
  const actions: HotKeyAction[] = Object.keys(hotKeys).reduce((a, c) => {
    let action = getActions(c, hotKeys[c]);
    action && a.push(action);
    return a;
  }, []);
  return actions;
  // return store.dispatch(updateHotKeys(actions));
}
function getActions(key, value) {
  switch (key) {
    case "Enter":
      return {
        keyCombo: ["Enter"],
        action: (event: KeyboardEvent) => {
          if (
            EditorModel.supportRenderingEngine &&
            EditorModel.supportRenderingEngine.labelType === LabelType.POLYGON
          ) {
            const editorData: EditorData = EditorActions.getEditorData();
            (
              EditorModel.supportRenderingEngine as PolygonRenderEngine
            ).addLabelAndFinishCreation(editorData);
          }
          if (
            EditorModel.supportRenderingEngine &&
            EditorModel.supportRenderingEngine.labelType === LabelType.POLYLINE
          ) {
            const editorData: EditorData = EditorActions.getEditorData();
            (
              EditorModel.supportRenderingEngine as PolylineRenderEngine
            ).addLabelAndFinishCreation(editorData);
          }
          EditorActions.fullRender();
        },
      };
    case "Escape":
      return {
        keyCombo: ["Escape"],
        action: (event: KeyboardEvent) => {
          // store.dispatch(updateImageDragModeStatus(true));
          if (EditorModel.supportRenderingEngine) {
            switch (EditorModel.supportRenderingEngine.labelType) {
              case LabelType.POLYGON:
                (
                  EditorModel.supportRenderingEngine as PolygonRenderEngine
                ).cancelLabelCreation();
                break;
              case LabelType.POLYLINE:
                (
                  EditorModel.supportRenderingEngine as PolylineRenderEngine
                ).cancelLabelCreation();
                break;
              case LabelType.LINE:
                (
                  EditorModel.supportRenderingEngine as LineRenderEngine
                ).cancelLabelCreation();
                break;
              case LabelType.RECT:
                (
                  EditorModel.supportRenderingEngine as RectRenderEngine
                ).cancelLabelCreation();
                break;
            }
          }
          EditorActions.fullRender();
        },
      };
    case "Delete":
      return {
        keyCombo: value,
        action: (event: KeyboardEvent) => {
          event.preventDefault();
          if ((event.target as HTMLInputElement).localName === "input") return;
          LabelActions.deleteActiveLabel();
        },
      };
      case "Hide Selected":
        return {
          keyCombo: value,
          action: (event: KeyboardEvent) => {
            event.preventDefault();
            event.stopPropagation();
            LabelActions.hideUnHideActiveLabel();
          },
        };
    case "Backspace":
      return {
        keyCombo: value,
        action: (event: KeyboardEvent) => {
          if ((event.target as HTMLInputElement).localName === "input") return;
          LabelActions.deleteActiveLabel();
        },
      };
    case "Next Image":
      return {
        keyCombo: value,
        action: (event: KeyboardEvent) => {
          event.preventDefault();
          if ((event.target as HTMLInputElement).localName === "input") return;
          ImageActions.getNextImage();
        },
      };
    case "Prev Image":
      return {
        keyCombo: value,
        action: (event: KeyboardEvent) => {
          event.preventDefault();
          if ((event.target as HTMLInputElement).localName === "input") return;
          ImageActions.getPreviousImage();
        },
      };
    // case "Pan Up":
    //     return {
    //         keyCombo: value, action: (event: KeyboardEvent) => {
    //             event.preventDefault();
    //             ViewPortActions.translateViewPortPosition(Direction.BOTTOM);
    //         },
    //     };
    // case "Pan Down":
    //     return {
    //         keyCombo: value, action: (event: KeyboardEvent) => {
    //             event.preventDefault();
    //             ViewPortActions.translateViewPortPosition(Direction.TOP);
    //         },
    //     };
    // case "Pan Left":
    //     return {
    //         keyCombo: value, action: (event: KeyboardEvent) => {
    //             event.preventDefault();
    //             ViewPortActions.translateViewPortPosition(Direction.LEFT);
    //         },
    //     };
    // case "Pan Right":
    // return {
    //     keyCombo: value, action: (event: KeyboardEvent) => {
    //         event.preventDefault();
    //         ViewPortActions.translateViewPortPosition(Direction.RIGHT);
    //     },
    // };
    case "Copy":
      return {
        keyCombo: value,
        action: (event: KeyboardEvent) => {
          event.preventDefault();
          if ((event.target as HTMLInputElement).localName === "input") return;
          EditorModel.supportRenderingEngine.copyHandler();
        },
      };
    case "Paste":
      return {
        keyCombo: value,
        action: (event: KeyboardEvent) => {
          event.preventDefault();
          if ((event.target as HTMLInputElement).localName === "input") return;
          EditorModel.supportRenderingEngine.pasteHandler();
        },
      };
    case "Draw":
      return {
        keyCombo: value,
        action: (event: KeyboardEvent) => {
          event.preventDefault();
          if ((event.target as HTMLInputElement).localName === "input") return;
          store.dispatch(
            updateImageDragModeStatus(!GeneralSelector.getImageDragModeStatus())
          );
        },
      };
    case "Undo":
      return {
        keyCombo: value,
        action: (event: KeyboardEvent) => {
          event.preventDefault();
          undo();
        },
      };
    case "Redo":
      return {
        keyCombo: value,
        action: (event: KeyboardEvent) => {
          event.preventDefault();
          redo();
        },
      };
    case "Zoom In":
      return {
        keyCombo: value,
        action: (event: KeyboardEvent) => {
          event.preventDefault();
          ViewPortActions.zoomIn();
        },
      };
    case "Zoom Out":
      return {
        keyCombo: value,
        action: (event: KeyboardEvent) => {
          event.preventDefault();
          ViewPortActions.zoomOut();
        },
      };
    case "Delete Label":
      return {
        keyCombo: value,
        action: (event: KeyboardEvent) => {
          event.preventDefault();
          LabelActions.deleteActiveLabel();
        },
      };
    case "Hide All":
      return {
        keyCombo: value,
        action: (event: KeyboardEvent) => {
          event.preventDefault();
          event.stopPropagation();
          LabelActions.hideRectLabelAll((hideAll = !hideAll));
        },
      };
      case "Hide All":
      return {
        keyCombo: value,
        action: (event: KeyboardEvent) => {
          event.preventDefault();
          event.stopPropagation();
          LabelActions.hidePolyLabelAll((hideAll = !hideAll));
        },
      };
    

    default:
      if (key.includes("label"))
        return {
          keyCombo: value,
          action: (event: KeyboardEvent) => {
            if ((event.target as HTMLInputElement).localName === "input")
              return;
            if (LabelsSelector.getActiveLabelId()) event.preventDefault();
            ImageActions.setActiveLabelOnActiveImage(
              parseInt(key.split(" ")[1])
            );
            store.dispatch(updateActiveLabelNameId(key.split(" ")[1]));
            EditorActions.fullRender();
          },
        };

      return null;
  }
}
