import { faBell, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownToggle,
  Nav,
  Progress,
  UncontrolledDropdown,
} from "reactstrap";
import "./Notifications.scss";
import getDataFromApi from "../getDataFromApi";
import ErrorScreen from "../error/ErrorScreen";

export default function Notifications() {
  const [data, setData] = useState({});
  function fetchData(url, method) {
    getDataFromApi(`${window["ENV"].API_URL}/view-api/${url}`, method)
      .then((result) => {
        if (result?.detail) {
          console.log(result);
        } else setData(result);
      })
      .catch((e) => {
        console.log(e.message);
      });
  }
  function convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;   
}
  function time_ago(time) {
    switch (typeof time) {
      case "number":
        break;
      case "string":
        time = +new Date(time + 'Z');
        break;
      case "object":
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    var time_formats = [
      [60, "s ago", 1], // 60
      // [120, '1 minute ago', '1 minute from now'], // 60*2
      [3600, "m ago", 60], // 60*60, 60
      // [7200, '1 hour ago', '1 hour from now'], // 60*60*2
      [86400, "H ago", 3600], // 60*60*24, 60*60
      // [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
      [604800, "D ago", 86400], // 60*60*24*7, 60*60*24
      // [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
      [2419200, "W ago", 604800], // 60*60*24*7*4, 60*60*24*7
      // [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
      [29030400, "M ago", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      // [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
      [2903040000, "Y ago", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
      [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    var seconds = (+new Date() - time) / 1000,
      token = "",
      list_choice = 1;

    if (seconds == 0) {
      return "Just now";
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = "from now";
      list_choice = 2;
    }
    var i = 0,
      format;
    // eslint-disable-next-line no-cond-assign
    while ((format = time_formats[i++]))
      if (seconds < format[0]) {
        if (typeof format[2] == "string") return format[list_choice];
        else
          return (
            Math.floor(seconds / format[2]) +""+ format[1] + "" + token
          );
      }
    return time;
  }
  useEffect(() => {
    fetchData(`notifications/`, "get");
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      fetchData(`notifications/`, "get");
    }, (data?.next_ping ?? 10) * 1000);
    return () => clearInterval(interval);
  }, [data]);

  return (
    <Nav className="ps-2 mt-1">
      <UncontrolledDropdown nav navbar="true">
        <DropdownToggle
          nav
          id="notifications"
          onClick={(e) => setData({ ...data, next_ping: 1 })}
        >
          {data?.unread_count > 0 && (
            <span className="px-2 notification-badge text-white">
              {data?.unread_count}
            </span>
          )}
          <FontAwesomeIcon icon={faBell} style={{ fontSize: "18px" }} />
        </DropdownToggle>
        <DropdownMenu
          className="grey-card p-4 pe-0 bg-grey"
          end
          style={{
            width: "450px",
            height: "500px",
            transform: "translate3d(0px, 50.2px, 0px)",
          }}
        >
          <h5 className="text-center fw-bold ">Notifications</h5>
          {data?.notifications?.length > 0 ? (
            <>
              <ul className="p-0">
                <div className="d-flex justify-content-between w-100 p-2">
                  <div
                    className="text-primary   fw-bold cursor-pointer"
                    onClick={() => fetchData(`notifications/`, "post")}
                  >
                    Read all
                  </div>
                  <div
                    className="text-primary   fw-bold cursor-pointer pe-3"
                    onClick={() => fetchData(`notifications/`, "delete")}
                  >
                    Clear all
                  </div>
                </div>
                <div
                  className="notify-container p-3 w-100"
                  style={{ overflowX: "hidden" }}
                >
                  <div className="d-flex flex-wrap">
                    {data.notifications.map((v) => (
                      <p
                        className="d-flex"
                        key={v.id}
                        style={{
                          width: "390px",
                          whiteSpace: "pre-wrap",
                          overflow: "hidden",
                          userSelect: "none",
                          opacity:
                            v.type === "event" && v.read === "True" ? "0.6" : 1,
                          borderRadius: "15px",
                          order:
                            v.type === "process" ? 0 : (v.read === "False" && v.type === "event") ? 1 : 2,
                        }}
                      >
                        {v.type === "event" ? (
                          <div
                            style={{ flexGrow: 1 }}
                            className="cursor-pointer my-2 p-3 h6 d-flex justify-content-between align-items-center grey-card"
                            onClick={() =>
                              fetchData(
                                `notifications/?notification_id=${v.id}`,
                                "post"
                              )
                            }
                          >
                            <span className="w-100 d-flex justify-content-between align-items-center"><span className="notification-message" title={v.message}>{v.message}</span><span style={{minWidth:55,color:"#a5a2a2",fontWeight:700}}>{time_ago(v.created_on)}</span></span>
                           
                            <FontAwesomeIcon
                              className="ms-2 cursor-pointer"
                              icon={faTimes}
                              onClick={(e) => {
                                e.preventDefault();
                                fetchData(
                                  `notifications/?notification_id=${v.id}`,
                                  "delete"
                                );
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            className="my-2 p-3 h6 grey-card"
                            style={{
                              width: "400px",
                              whiteSpace: "pre-wrap",
                              overflow: "hidden",
                              flexGrow: 1,
                            }}
                          >
                            <div className="pb-2">{v.message}</div>
                            <Progress value={v.progress} animated>
                              {v.progress}
                            </Progress>
                          </div>
                        )}
                      </p>
                    ))}
                  </div>
                </div>
              </ul>
            </>
          ) : (
            <div
              style={{
                position: "absolute",
                bottom: "calc(50% - 120px)",
                left: 0,
                right: 0,
              }}
            >
              <ErrorScreen message={"All notifications Are Read"} sub="false" />
            </div>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  );
}
