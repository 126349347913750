import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useState } from "react";
import Modal from "react-modal";
import { Button } from "reactstrap";
import AlertComponent from "../alert/AlertComponent";
import "./ModalComponent.scss";
Modal.setAppElement("body");
export const alertContext = React.createContext();

export default function ModalComponent({
  modalIsOpen,
  setModalIsOpen,
  modalClass,
  component,
}) {
  const closeModal = () => {
    setModalIsOpen();
  };
  const [alert, setAlert] = useState();

  return (
    <div>
      <AlertComponent alert={alert} />
      <Modal
        className={modalClass}
        isOpen={modalIsOpen?.length > 0}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <Button
          className="float-right"
          color="transparant"
          onClick={() => {
            closeModal();
          }}
          style={{
            position: "sticky",
            zIndex: 9999,
            top: 0,
            marginRight: "-8px",
          }}
        >
          <FontAwesomeIcon
            icon={faTimes}
            color="grey"
            size="lg"
          ></FontAwesomeIcon>
        </Button>
        <alertContext.Provider value={setAlert}>
          {component}
        </alertContext.Provider>
      </Modal>
    </div>
  );
}
