import { LabelsSelector } from "../../store/selectors/LabelsSelector";
import {
  ImageData,
  LabelLine,
  LabelName,
  LabelPoint,
  LabelPolygon,
  LabelPolyline,
  LabelRect,
} from "../../store/labels/types";
import { filter } from "lodash";
import { store } from "../../index";
import {
  updateImageData,
  updateImageDataById,
  updateLabelNames,
} from "../../store/labels/actionCreators";
import { LabelType } from "../../data/enums/LabelType";

export class LabelActions {
  public static deleteActiveLabel() {
    const activeImageData: ImageData = LabelsSelector.getActiveImageData();
    const activeLabelId: string = LabelsSelector.getActiveLabelId();
    LabelActions.deleteImageLabelById(activeImageData.image_id, activeLabelId);
  }
  //******************************************************************* */
  //color picker to change label names
  public static onChangeColor(id, name, color) {
    if (id) {
      const labels = LabelsSelector.getLabelNames().map((v) =>
        // eslint-disable-next-line eqeqeq
        id == v.id ? { id, name, color } : v
      );
      store.dispatch(updateLabelNames(labels));
    }

  }
  //********************************************************************** */
  public static deleteImageLabelById(imageId: string, labelId: string) {
    switch (LabelsSelector.getActiveLabelType()) {
      case LabelType.POINT:
        LabelActions.deletePointLabelById(imageId, labelId);
        break;
      case LabelType.RECT:
        LabelActions.deleteRectLabelById(imageId, labelId);
        break;
      case LabelType.POLYGON:
        LabelActions.deletePolygonLabelById(imageId, labelId);
        break;
      case LabelType.POLYLINE:
        LabelActions.deletePolylineLabelById(imageId, labelId);
        break;
    }
  }

  public static deleteRectLabelById(imageId: string, labelRectId: string) {
    const imageData: ImageData = LabelsSelector.getImageDataById(imageId);
    const newImageData = {
      ...imageData,
      labelRects: filter(imageData.labelRects, (currentLabel: LabelRect) => {
        return currentLabel.id !== labelRectId;
      }),
    };
    store.dispatch(updateImageDataById(imageData.image_id, newImageData));
  }


  public static deleteSelectRectLabelById(imageId: string, list:any[]) {
    const imageData: ImageData = LabelsSelector.getImageDataById(imageId);
    // window.alert(list)
    let newImageData ={...imageData}
    list.map((i)=>{
      console.log(i)
      newImageData = {
        ...newImageData,
        labelRects: filter(newImageData.labelRects, (currentLabel: LabelRect) => {
          return currentLabel.id !== i;
        }),
      };
    })

    store.dispatch(updateImageDataById(imageData.image_id, newImageData));
  }

  public static deleteSelectPolygonsLabelById(imageId: string, list:any[]) {
    // window.alert(list)
    const imageData: ImageData = LabelsSelector.getImageDataById(imageId);
    let newImageData ={...imageData}
    list.map((i)=>{
      console.log(i)
      newImageData = {
        ...newImageData,
        labelPolygons: filter(newImageData.labelPolygons, (currentLabel: LabelPolygon) => {
          return currentLabel.id !== i;
        }),
      };
    })

  store.dispatch(updateImageDataById(imageData.image_id, newImageData));
}

  public static hideunhideRectLabelById(imageId: string, labelRectId: string) {
    const imageData: ImageData = LabelsSelector.getImageDataById(imageId);
    const newImageData = {
      ...imageData,
      labelRects: imageData.labelRects.map((item) => {
        // item.show = false
        
        if (item.id === labelRectId) {
          item.show = !(item.show != false)
        }
        return item;
      })
    };
    store.dispatch(updateImageDataById(imageData.image_id, newImageData));
  }
  public static hideselected(imageId: string, selected: any[]) {
    const imageData: ImageData = LabelsSelector.getImageDataById(imageId);
    const activetype = LabelsSelector.getActiveLabelTypeName();
    const newImageData = {
      ...imageData,
      activetype: imageData[activetype].map((item) => {
        // item.show = false
        
        if (selected.some(el => el === item.id)) {
          item.show = !(item.show != false)
        }
        return item;
      })
    };
    store.dispatch(updateImageDataById(imageData.image_id, newImageData));
  }
  
  
  public static unhideRectLabelById(imageId: string, labelRectId: string) {
    const imageData: ImageData = LabelsSelector.getImageDataById(imageId);
    const newImageData = {
      ...imageData,
      labelRects: imageData.labelRects.map((item) => {

      
          item.show = true
        return item;

      })
    };
    store.dispatch(updateImageDataById(imageData.image_id, newImageData));
  }
  public static hideunhidePolygonLabelById(imageId: string, labelPolygonId: string) {
    const imageData: ImageData = LabelsSelector.getImageDataById(imageId);
    const newImageData = {
      ...imageData,
      labelPolygons: imageData.labelPolygons.map((item) => {
        // item.show = false
        
        if (item.id === labelPolygonId) {
          item.show = !(item.show != false)
        }
        return item;
      })
    };
    store.dispatch(updateImageDataById(imageData.image_id, newImageData));
  }
  public static hideUnHideActiveLabel() {
    const activeImageData: ImageData = LabelsSelector.getActiveImageData();
    const activeLabelId: string = LabelsSelector.getActiveLabelId();
    const activeLabelType:string =LabelsSelector.getActiveLabelTypeName();
    console.log(activeLabelType)
    const newImageData = {
      ...activeImageData,
      activeLabelType: activeImageData[activeLabelType].map((item) => {
        // item.show = false
        
        if (item.id === activeLabelId) {
          item.show = !(item.show != false)
        }
        return item;
      })
    };
    store.dispatch(updateImageDataById(activeImageData.image_id, newImageData));
  }
  public static hideRectLabelAll(hide) {
    console.log("hideall", hide)
    
    const imageData: ImageData = LabelsSelector.getActiveImageData();
    const newImageData = {
      ...imageData,
      labelRects: imageData.labelRects.map((item) => {
        item.show = hide
        return item;

      })
    };
    store.dispatch(updateImageDataById(imageData.image_id, newImageData));
  }
  public static hidePolyLabelAll(hide) {
    console.log("hideall", hide)
    
    const imageData: ImageData = LabelsSelector.getActiveImageData();
    const newImageData = {
      ...imageData,
      labelRects: imageData.labelRects.map((item) => {
        item.show = hide
        return item;

      })
    };
    store.dispatch(updateImageDataById(imageData.image_id, newImageData));
  }
  public static hideLabelAll(hide) {
    console.log("hideall", hide)
    const label = LabelsSelector.getActiveLabelTypeName();
    const imageData: ImageData = LabelsSelector.getActiveImageData();
    const newImageData = imageData;
    newImageData[label]=imageData[label].map((item) => {

      item.show = hide
      return item;

    })
  
    store.dispatch(updateImageDataById(imageData.image_id, newImageData));
  }
  // public static hideLabelByClass(imageId: string, labellist) {
  //   const label = LabelsSelector.getActiveLabelTypeName();
  //   const imageData: ImageData = LabelsSelector.getImageDataById(imageId);
  //   const newImageData = imageData;
  //   newImageData[label]=imageData[label].map((item) => {

  //     if (labellist.includes(item.labelId)) {
  //       item.show = false
  //     }
  //     else {
  //       item.show = true
  //     }
  //     return item;

  //   })
  //   store.dispatch(updateImageDataById(imageData.image_id, newImageData));
  // }
  public static hideLabelByClass(imageId: string, labellist) {
    const label = LabelsSelector.getActiveLabelTypeName();
    const imageData: ImageData = LabelsSelector.getImageDataById(imageId);
    const newImageData = {
      ...imageData,
      [label]: imageData[label].map((item) => {

        if (labellist.includes(item.labelId)) {
          item.show = false
        }
        else {
          item.show = true
        }
        return item;

      })
    };
    store.dispatch(updateImageDataById(imageData.image_id, newImageData));
  }
  public static hideAll(imageId: string, labelRectId: string) {
    const imageData: ImageData = LabelsSelector.getImageDataById(imageId);
    const newImageData = {
      ...imageData,
      labelRects: imageData.labelRects.map((item) => {

        if (item.id === labelRectId) {
          item.show = !(item.show != false)
        }
        return item;

      })
    };
    store.dispatch(updateImageDataById(imageData.image_id, newImageData));
  }
  public static deletePointLabelById(imageId: string, labelPointId: string) {
    const imageData: ImageData = LabelsSelector.getImageDataById(imageId);
    const newImageData = {
      ...imageData,
      labelPoints: filter(imageData.labelPoints, (currentLabel: LabelPoint) => {
        return currentLabel.id !== labelPointId;
      }),
    };
    store.dispatch(updateImageDataById(imageData.image_id, newImageData));
  }
  public static hidePointLabelById(imageId: string, labelPointId: string) {
    const imageData: ImageData = LabelsSelector.getImageDataById(imageId);
    const newImageData = {
      ...imageData,
      labelPoints: imageData.labelPoints.map((item) => {

        if (item.id === labelPointId) {
          item.show = !(item.show != false)
        }
        return item;

      })
    };
    store.dispatch(updateImageDataById(imageData.image_id, newImageData));
  }

  public static deleteLineLabelById(imageId: string, labelLineId: string) {
    const imageData: ImageData = LabelsSelector.getImageDataById(imageId);
    const newImageData = {
      ...imageData,
      labelLines: filter(imageData.labelLines, (currentLabel: LabelLine) => {
        return currentLabel.id !== labelLineId;
      }),
    };
    store.dispatch(updateImageDataById(imageData.image_id, newImageData));
  }

  public static deletePolygonLabelById(
    imageId: string,
    labelPolygonId: string
  ) {
    const imageData: ImageData = LabelsSelector.getImageDataById(imageId);
    const newImageData = {
      ...imageData,
      labelPolygons: filter(
        imageData.labelPolygons,
        (currentLabel: LabelPolygon) => {
          return currentLabel.id !== labelPolygonId;
        }
      ),
    };
    store.dispatch(updateImageDataById(imageData.image_id, newImageData));
  }
  public static deletePolylineLabelById(
    imageId: string,
    labelPolylineId: string
  ) {
    const imageData: ImageData = LabelsSelector.getImageDataById(imageId);
    const newImageData = {
      ...imageData,
      labelPolylines: filter(
        imageData.labelPolylines,
        (currentLabel: LabelPolyline) => {
          return currentLabel.id !== labelPolylineId;
        }
      ),
    };
    store.dispatch(updateImageDataById(imageData.image_id, newImageData));
  }

  public static removeLabelNames(labelNamesIds: string[]) {
    const imagesData: ImageData[] = LabelsSelector.getImagesData();
    const newImagesData: ImageData[] = imagesData.map(
      (imageData: ImageData) => {
        return LabelActions.removeLabelNamesFromImageData(
          imageData,
          labelNamesIds
        );
      }
    );
    store.dispatch(updateImageData(newImagesData));
  }

  private static removeLabelNamesFromImageData(
    imageData: ImageData,
    labelNamesIds: string[]
  ): ImageData {
    return {
      ...imageData,
      labelRects: imageData.labelRects.map((labelRect: LabelRect) => {
        if (labelNamesIds.includes(labelRect.id)) {
          return {
            ...labelRect,
            id: null,
          };
        } else {
          return labelRect;
        }
      }),
      labelPoints: imageData.labelPoints.map((labelPoint: LabelPoint) => {
        if (labelNamesIds.includes(labelPoint.id)) {
          return {
            ...labelPoint,
            id: null,
          };
        } else {
          return labelPoint;
        }
      }),
      labelPolygons: imageData.labelPolygons.map(
        (labelPolygon: LabelPolygon) => {
          if (labelNamesIds.includes(labelPolygon.id)) {
            return {
              ...labelPolygon,
              id: null,
            };
          } else {
            return labelPolygon;
          }
        }
      ),
      labelPolylines: imageData.labelPolylines.map(
        (labelPolyline: LabelPolyline) => {
          if (labelNamesIds.includes(labelPolyline.id)) {
            return {
              ...labelPolyline,
              id: null,
            };
          } else {
            return labelPolyline;
          }
        }
      ),
      labelTags: imageData.labelTags.filter((labelNameId: string) => {
        return !labelNamesIds.includes(labelNameId);
      }),
    };
  }

  public static labelExistsInLabelNames(label: string): boolean {
    const labelNames: LabelName[] = LabelsSelector.getLabelNames();
    return labelNames
      .map((labelName: LabelName) => labelName.name)
      .includes(label);
  }
}
