import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Form, FormGroup, Input, Label, Button, Alert } from "reactstrap";
import getDataFromApi from "../../components/getDataFromApi";
import "./Login.scss";
export function getCokkie(key) {
  try {
    return document.cookie
      .split("; ")
      .find((row) => row.startsWith(key))
      .split("=")[1];
  } catch (err) {
    console.log("No user Found");
  }
}
export async function logout() {
  getDataFromApi(`${window['ENV'].API_URL}/view-api/home/logout`, "delete")
    .then((r) => console.log(r))
    .catch(e => console.log(e));
  document.cookie = `jwtToken=;path=/`;
  localStorage.clear();
  window.location.replace("/login");
}


export default function Login() {
  return (
    <div className="row login-page align-items-center">
      <div className="col-6 login-bg">
        <a
          href="www.deepedge.ai"
          className="navbar-brand d-flex align-items-center text-secondary mb-auto h2 mt-5"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="50px"
            viewBox="0 0 31 28"
            fill="none"
          >
            <path
              d="M23 3h-9C7.925 3 3 7.925 3 14v0c0 6.075 4.925 11 11 11h9M21 14h7"
              stroke="#3755d3"
              strokeWidth="6"
              strokeLinecap="round"
            ></path>
          </svg>
          <span className="h2  fw-bold ps-2 mt-2">DeepEdge</span>
        </a>
        {/* <img
          src="./collage.png"
          className="w-100 border-1"
          alt="deepEdge banner"
        /> */}
        <h1>Deep Learning platform for Edge Applications</h1>
        <p className="mt-auto mb-5">
          Copyright © DeepEdge 2019-{new Date().getFullYear()}. All rights reserved.
        </p>
      </div>
      <div className="col-6 login-bg-right">
        <div className="login-form">
          {`${window.ENV.LOGO_URL}` != "undefined" && (
            <img
              style={{ width: "150px" }}
              className="bg-white p-2"
              src={`${window.ENV.LOGO_URL}`}
              alt="partner_logo"
            />
          )}
          <LoginForm />

        </div>
      </div>
    </div>
  );
}
export function LoginForm() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [alertMsg, setAlertMsg] = React.useState({});
  const [show, setShow] = React.useState(false);
  function forgotPassword() {
    getDataFromApi(`${window['ENV'].API_URL}/view-api/authentication/forgot_password`, "get")
      .then((r) => setAlertMsg(r?.detail))
      .catch(e => console.log(e));
  }
  const auth = async (e) => {
    e.preventDefault();
    var urlencoded = new URLSearchParams();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    urlencoded.append("username", email);
    urlencoded.append("password", password);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
      credentials: "include",
    };
    await fetch(
      `${window['ENV'].API_URL}/view-api/authentication/login`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result?.access_token) {
          const date = new Date(result.expire);
          document.cookie = `jwtToken=${result.access_token
            };path=/;expires=${date.toUTCString()};samesite=Lax`;
          localStorage.setItem("name", result.user_name);
          localStorage.setItem("projectId", result.first_project_id);
          localStorage.setItem("navImage", result.user_image);
          localStorage.setItem("role", result.role);
          localStorage.setItem("screen_permissions", JSON.stringify(result?.screen_permissions ?? []));
          localStorage.setItem("hotKeys", JSON.stringify(result?.hot_keys));
          navigate(0)
        }
        else
          setAlertMsg(result?.detail ?? { message: "Internal Server Error" });
      })
      .catch((e) => {
        setAlertMsg({ message: "Internal Server Error" });
        console.log(e);
      });
  };
  return <Form onSubmit={auth} className="bg-light p-5 shadow grey-card">
    {alertMsg?.message?.length > 0 && <Alert color="danger">{alertMsg.message}</Alert>}

    <FormGroup>
      <Label for="userEmail">Email</Label>
      <Input
        // onChange={(e) => setUsername(e.target.value)}
        type="email"
        name="email"
        id="userEmail"
        required={true}
        placeholder=" Email Id"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
    </FormGroup>
    <FormGroup>
      <Label for="password">Password</Label>
      <div className="password-container">
        <Input
          type={show ? "text" : "Password"}
          id="password"
          value={password}
          placeholder="Password"
          required={true}
          name="password"
          onChange={(e) => setPassword(e.target.value)}
        ></Input>

        <FontAwesomeIcon
          className="eye-btn"
          onClick={() => setShow(!show)}

          icon={show ? faEye : faEyeSlash} />
      </div>
    </FormGroup>
    { // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        href="#"
        onClick={forgotPassword}
      >
        Forgot password?
      </a>}
    <br></br>
    <Button className="my-3" size="lg" color="primary" type="submit">
      Login
    </Button>
    {/* <p>
    Do not have your login credentials? Contact your Admin for
    details.
  </p> */}
  </Form>
}