import { ContextType } from "../../../data/enums/ContextType";
import "./EditorTopNavigationBar.scss";
import React, { useState } from "react";
import classNames from "classnames";
import { AppState } from "../../../store";
import { connect } from "react-redux";
import {
  updateAiModeStatus,
  updateCrossHairVisibleStatus,
  updateImageDragModeStatus,
} from "../../../store/general/actionCreators";
import { GeneralSelector } from "../../../store/selectors/GeneralSelector";
import { ViewPointSettings } from "../../../settings/ViewPointSettings";
import { ImageButton } from "../../Common/ImageButton/ImageButton";
import { ImageData } from "../../../store/labels/types";
import { ViewPortActions } from "../../../logic/actions/ViewPortActions";
import { LabelsSelector } from "../../../store/selectors/LabelsSelector";
import { LabelType } from "../../../data/enums/LabelType";
import { AISelector } from "../../../store/selectors/AISelector";
import { ISize } from "../../../interfaces/ISize";

import withStyles from "@material-ui/core/styles/withStyles";
import { Badge, Tooltip } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import FlagLabelsLists from "../SideNavigationBar/TagLabelsList/flagLabelsList";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  Label,
  Button,
} from "reactstrap";
import { isPropertySignature } from "typescript";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPalette } from "@fortawesome/free-solid-svg-icons";
import { undoRedo, updateActiveLabelId, updateImageDataById } from "../../../store/labels/actionCreators";
import { store } from "../../..";
import ImageComments from "./ImageComments";
import { getCokkie } from "../../login/Login";
import { CanvasUtil } from "../../../utils/CanvasUtil";
import { EditorModel } from "../../../staticModels/EditorModel";
import { EditorActions } from "../../../logic/actions/EditorActions";
import { PolygonRenderEngine } from "../../../logic/render/PolygonRenderEngine";
interface IProps { }

// interface IState {
//     size: ISize;
// }
// const [imagecount, setImagecount] = useState([]);
// class imagesData extends React.Component<IProps, IState> {
//     setImagecount(imagesData.length)
// }
const BUTTON_SIZE: ISize = { width: 40, height: 40 };
const BUTTON_PADDING: number = 10;


export const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#171717",
    color: "#ffffff",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    maxWidth: 200,
    textAlign: "center",
  },
}))(Tooltip);

const getButtonWithTooltip = (
  key: string,
  tooltipMessage: string,
  imageSrc: string,
  imageAlt: string,
  isActive: boolean,
  href?: string,
  onClick?: () => any
): React.ReactElement => {
  return (
    <StyledTooltip
      key={key}
      disableFocusListener={true}
      title={tooltipMessage}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      placement="bottom"
    >
      <div>
        <ImageButton
          buttonSize={BUTTON_SIZE}
          padding={BUTTON_PADDING}
          image={imageSrc}
          imageAlt={imageAlt}
          href={href}
          onClick={onClick}
          isActive={isActive}
        />
      </div>
    </StyledTooltip>
  );
};
// export const undo = () => {

//   var number = JSON.parse(localStorage.getItem(`undoindex_${LabelsSelector.getActiveImageData().image_id}`));
//   if (JSON.parse(localStorage.getItem(`undoRedo_${LabelsSelector.getActiveImageData().image_id}`)).length > number + 1) {
//     localStorage.setItem(`undoindex_${LabelsSelector.getActiveImageData().image_id}`, JSON.stringify(number + 1))
//     store.dispatch(undoRedo(LabelsSelector.getActiveImageData().image_id, JSON.parse(localStorage.getItem(`undoRedo_${LabelsSelector.getActiveImageData().image_id}`))[number + 1]))
//   }
// };


export const undo = () => {
  if(localStorage.getItem('mousePositionPoints')) {
    (EditorModel.supportRenderingEngine as PolygonRenderEngine).undo();
  } else {
      var number = JSON.parse(localStorage.getItem(`undoindex_${LabelsSelector.getActiveImageData().image_id}`));
  if (JSON.parse(localStorage.getItem(`undoRedo_${LabelsSelector.getActiveImageData().image_id}`)).length > number + 1) {
    localStorage.setItem(`undoindex_${LabelsSelector.getActiveImageData().image_id}`, JSON.stringify(number + 1))
    store.dispatch(undoRedo(LabelsSelector.getActiveImageData().image_id, JSON.parse(localStorage.getItem(`undoRedo_${LabelsSelector.getActiveImageData().image_id}`))[number + 1]))
  }
  }
}
export const redo = () => {
  if(localStorage.getItem('mousePositionPoints')) {
    (EditorModel.supportRenderingEngine as PolygonRenderEngine).redo();
  } else {
  var number = JSON.parse(localStorage.getItem(`undoindex_${LabelsSelector.getActiveImageData().image_id}`));
  if (number != 0) {
    localStorage.setItem(`undoindex_${LabelsSelector.getActiveImageData().image_id}`, JSON.stringify(number - 1))
    store.dispatch(undoRedo(LabelsSelector.getActiveImageData().image_id, JSON.parse(localStorage.getItem(`undoRedo_${LabelsSelector.getActiveImageData().image_id}`))[number - 1]))
  }
}

};
interface IProps {
  activeContext: ContextType;
  updateImageDragModeStatusAction: (imageDragMode: boolean) => any;
  updateAiModeStatusAction: (aiMode: boolean) => any;
  updateCrossHairVisibleStatusAction: (crossHairVisible: boolean) => any;
  imageDragMode: boolean;
  aiMode: boolean;
  aiRectMode: boolean;
  aiPointMode: boolean;
  crossHairVisible: boolean;
  activeLabelType: LabelType;
  activeImageIndex: number;
  imagesData: ImageData[];
}

const EditorTopNavigationBar: React.FC<IProps> = ({
  activeContext,
  updateImageDragModeStatusAction,
  updateAiModeStatusAction,
  updateCrossHairVisibleStatusAction,
  imageDragMode,
  crossHairVisible,
  activeLabelType,
  activeImageIndex,
  imagesData,
  aiMode,
  aiRectMode,
  aiPointMode
}) => {
  const FlagLabeledImages = imagesData.reduce(
    (currentCount: number, currentImage: ImageData) => {
      return currentCount + (currentImage.flags.length !== 0 ? 1 : 0);
    },
    0
  );
  // var aimode=false
  const LabeledImages = imagesData.reduce(
    (currentCount: number, currentImage: ImageData) => {
      return (
        currentCount +
        (currentImage.labelRects.length != 0 ||
          currentImage.labelPoints.length != 0 ||
          currentImage.flags.length != 0 ||
          currentImage.labelPolygons.length != 0 ||
          currentImage.labelPolylines.length != 0 ||
          currentImage.labelLines.length != 0||
          currentImage.labelTags.length !=0
          ? 1
          : 0)
      );
    },
    0
  );
  const getClassName = () => {
    return classNames("EditorTopNavigationBar grey-card", {
      "with-context": activeContext === ContextType.EDITOR,
    });
  };

  const imageDragOnClick = () => {
    updateImageDragModeStatusAction(!imageDragMode);
    // localStorage.setItem("aipreditions", JSON.stringify(""))
    updateAiModeStatusAction(false)
    setShowButtons(false)
    localStorage.setItem('aiMode', JSON.stringify(''));
  };


  const [showButtons, setShowButtons] = useState(false)
  const [aipridictionPoint, setaipridictionPoint] = useState(false)
  const [aipridictionRect, setaipridictionRect] = useState(false)



  const aipridition = (e) => {
    // updateImageDragModeStatusAction(true);    
    // localStorage.setItem("aipreditions", JSON.stringify(true))
    // updateImageDragModeStatusAction(true);
    // updateAiModeStatusAction(!aiMode)
    // aimode=(!aimode)


    e.preventDefault();
    setaipridictionPoint(true)
    setaipridictionRect(false)
    updateAiModeStatusAction(true)
    localStorage.setItem('aiMode', JSON.stringify('points'));
  };

  
  const acceptpridiction = () => {
    // var imageData =LabelsSelector.getActiveImageData();
    //   let pridictions = JSON.parse(localStorage.getItem("aipreditions"))
    //   imageData.labelPolygons = imageData.labelPolygons.concat(pridictions[0][0])
    //   localStorage.setItem("aipreditions", JSON.stringify(""))
    //   store.dispatch(updateImageDataById(imageData.image_id, imageData))
    //   store.dispatch(updateActiveLabelId(pridictions[0][0].id));
    //   updateImageDragModeStatusAction(!imageDragMode);
  };
  const clearpridiction = () => {
    localStorage.setItem("aipreditions", JSON.stringify(""))
  }

  const crossHairOnClick = () => {
    updateCrossHairVisibleStatusAction(!crossHairVisible);
  };

  const buttonsSlide = (e) => {
    e.preventDefault();
    updateAiModeStatusAction(true)
    updateImageDragModeStatusAction(true);
    setShowButtons(true)
    setaipridictionPoint(true)
    localStorage.setItem('aiMode', JSON.stringify('points'));
  }


  const handleaipridictionRects = (e) => {
    e.preventDefault();
    setaipridictionRect(true)
    setaipridictionPoint(false)
    localStorage.setItem('aiMode', JSON.stringify('rects'));
  }

  const getLabelTypeIcon = () => {
    switch (activeLabelType) {
      case LabelType.RECT:
        return "/ico/rectangle.png";
      case LabelType.POLYGON:
        return "/ico/polygon.png";
      case LabelType.POLYLINE:
        return "/ico/polyline.png";
        case LabelType.POINT : 
        return "/ico/point.png";
        case LabelType.TAG : 
        return "/ico/tags.png"
        case LabelType.LINE :
          return "/ico/line.png";
      default:
        return "/ico/default.png";
    }
  };

  return (
    <div className={getClassName()}>
      <div className="ButtonWrapper">
        {getButtonWithTooltip(
          "image-drag-mode",
          "draw mode",
          imageDragMode ? "/ico/pencil.png" : getLabelTypeIcon(),
          "image-draw-mode",
          !imageDragMode,
          undefined,
          imageDragOnClick
        )}
        
        <div className={`aibuttons ${showButtons ? 'aibuttonsshow' : ''}`}>
        <StyledTooltip title="magic rect">
            <div className="Imagediv aibuttons aibuttonsshowing aimainbuttonimage" style={{background:showButtons ? 'rgba(55, 85, 211, 1)' : ''}}>
          <img src="/ico/wand.png" onClick={buttonsSlide} style={{ filter:showButtons ? 'brightness(0) invert(1)' : ''}}/>
          </div>
          </StyledTooltip>
         <div className={`aibuttons ${showButtons ? 'aibuttonsshowing' : 'aibuttonshide'}`}>
          <StyledTooltip title="magic point">
          <div className="Imagediv" style={{background:aipridictionPoint ? 'rgba(55, 85, 211, 1)' : ''}}>
          <img src="/ico/point.png" onClick={aipridition} style={{ filter:aipridictionPoint ? 'brightness(0) invert(1)' : '' }}/>
          </div>
          </StyledTooltip>
          <StyledTooltip title="magic rect">
            <div className="Imagediv" style={{background:aipridictionRect ? 'rgba(55, 85, 211, 1)' : ''}}>
          <img src="/ico/rectangle.png" onClick={handleaipridictionRects} style={{ filter:aipridictionRect ? 'brightness(0) invert(1)' : ''}}/>
          </div>
          </StyledTooltip>
         </div>
          
        </div>
        {getButtonWithTooltip(
          "cursor-cross-hair",
          crossHairVisible
            ? "turn-off cursor cross-hair"
            : "turn-on cursor cross-hair",
          "/ico/cross-hair.png",
          "cross-hair",
          crossHairVisible,
          undefined,
          crossHairOnClick
        )}
        {getButtonWithTooltip(
          "undo",
          "undo",
          "/ico/undo.png",
          "undo",
          null,
          undefined,
          undo,
        )}
        {getButtonWithTooltip(
          "undo",
          "redo",
          "/ico/redo.png",
          "redo",
          null,
          undefined,
          redo,
        )}
        {/* <div
          className={
            localStorage.getItem("grey-scale") !== "true"
              ? "ImageButton p-2 active"
              : "ImageButton p-2"
          }
          title="Turn off grey scale"
          id="grey-scale"
          onClick={(e) => {
            localStorage.setItem(
              "grey-scale",
              localStorage.getItem("grey-scale") == "true" ? "false" : "true"
            );
            document.getElementById("grey-scale").classList.toggle("active");
          }}
        >
          <FontAwesomeIcon icon={faPalette} style={{ fontSize: "26px" }} />
        </div> */}
      </div>
     
      <div className="ButtonWrapper">
        {getButtonWithTooltip(
          "zoom-in",
          "zoom in",
          "/ico/zoom-in.png",
          "zoom-in",
          false,
          undefined,
          () => ViewPortActions.zoomIn()
        )}
        {getButtonWithTooltip(
          "zoom-out",
          "zoom out",
          "/ico/zoom-out.png",
          "zoom-out",
          false,
          undefined,
          () => ViewPortActions.zoomOut()
        )}
        {getButtonWithTooltip(
          "zoom-fit",
          "fit image to available space",
          "/ico/zoom-fit.png",
          "zoom-fit",
          false,
          undefined,
          () => ViewPortActions.setDefaultZoom()
        )}
        {getButtonWithTooltip(
          "zoom-max",
          "maximum allowed image zoom",
          "/ico/zoom-max.png",
          "zoom-max",
          false,
          undefined,
          () => ViewPortActions.setOneForOneZoom()
        )}
      </div>
      {/* {(LabelsSelector.getActiveLabelType()=="POLYGON"||LabelsSelector.getActiveLabelType()=="RECT") &&
      <div className="ButtonWrapper">
    
        </div>
    } */}
       
        {/* <div
          className={
            localStorage.getItem("grey-scale") !== "true"
              ? "ImageButton p-2 active"
              : "ImageButton p-2"
          }
          title="Turn off grey scale"
          id="grey-scale"
          onClick={(e) => {
            localStorage.setItem(
              "grey-scale",
              localStorage.getItem("grey-scale") == "true" ? "false" : "true"
            );
            document.getElementById("grey-scale").classList.toggle("active");
          }}
        >
          <FontAwesomeIcon icon={faPalette} style={{ fontSize: "26px" }} />
        </div> */}
    </div>
  );
};

const mapDispatchToProps = {
  updateImageDragModeStatusAction: updateImageDragModeStatus,
  updateAiModeStatusAction:updateAiModeStatus,
  updateCrossHairVisibleStatusAction: updateCrossHairVisibleStatus,
};

const mapStateToProps = (state: AppState) => ({
  activeContext: state.general.activeContext,
  imageDragMode: state.general.imageDragMode,
  aiMode:state.general.aiMode,
  crossHairVisible: state.general.crossHairVisible,
  activeLabelType: state.labels.activeLabelType,
  activeImageIndex: state.labels.activeImageIndex,
  imagesData: state.labels.imagesData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorTopNavigationBar);
