import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./configureStore";
import { Provider } from "react-redux";
import { AppInitializer } from "./logic/initializer/AppInitializer";

export const store = configureStore();

AppInitializer.inti();
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <Provider store={store}>
    <App />
  </Provider>,
);

serviceWorker.unregister();
