import { round } from "lodash";
import { IPoint } from "../interfaces/IPoint";
import { IRect } from "../interfaces/IRect";
import { UnitUtil } from "./UnitUtil";
import { ImageUtil } from "./ImageUtil";
import { EditorModel } from "../staticModels/EditorModel";
import { ViewPortActions } from "../logic/actions/ViewPortActions";

export class DrawUtil {
  public static clearCanvas(canvas: HTMLCanvasElement): void {
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  public static drawLine(
    canvas: HTMLCanvasElement,
    startPoint: IPoint,
    endPoint: IPoint,
    color: string = "#111111",
    thickness: number = 1
  ): void {
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.save();
    ctx.strokeStyle = color;
    ctx.lineWidth = thickness;
    ctx.lineCap = "round";
    ctx.beginPath();
    ctx.moveTo(startPoint.x, startPoint.y);
    ctx.lineTo(endPoint.x + 1, endPoint.y + 1);
    ctx.stroke();
    ctx.restore();
  }

  public static drawRect(
    canvas: HTMLCanvasElement,
    LabelName: string,
    rect: IRect,
    color: string = "#fff",
    thickness: number = 1
  ): void {
    // const scale =
    //   ImageUtil.getSize(EditorModel.image).width /
    //   ViewPortActions.calculateViewPortContentImageRect().width;
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.save();
    ctx.strokeStyle = color;
    ctx.lineWidth = thickness;
    ctx.beginPath();
    ctx.rect(rect.x, rect.y, rect.width, rect.height);
    // var text =
    //   LabelName +
    //   " (W " +
    //   round(rect.width * scale) +
    //   ", H " +
    //   round(rect.height * scale) +
    //   ")";
    ctx.stroke();
    ctx.restore();
    // ctx.font = "bold 1rem Montserrat";
    // ctx.fillStyle = "#fff";
    // ctx.fillText(text, rect.x, rect.y - 8);

    // ctx.shadowColor = "#666666";
    // ctx.shadowBlur = 7;
  }
  public static drawRectWithText(
    canvas: HTMLCanvasElement,
    LabelName: string,
    rect: IRect,
    color: string = "#fff",
    thickness: number = 1
  ): void {
    const scale =
      ImageUtil.getSize(EditorModel.image).width /
      ViewPortActions.calculateViewPortContentImageRect().width;
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.save();
    ctx.strokeStyle = color;
    ctx.lineWidth = thickness;
    ctx.beginPath();
    ctx.rect(rect.x, rect.y, rect.width, rect.height);
    var text =
      LabelName +
      " (W " +
      round(rect.width * scale) +
      ", H " +
      round(rect.height * scale) +
      ")";
    ctx.stroke();
    ctx.restore();
    ctx.font = "bold 1rem Montserrat";
    ctx.fillStyle = "#fff";
    ctx.fillText(text, rect.x, rect.y - 8);

    ctx.shadowColor = "#666666";
    ctx.shadowBlur = 7;
  }

  public static drawRectWithFill(
    canvas: HTMLCanvasElement,
    rect: IRect,
    color: string = "#fff"
  ): void {
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.save();
    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.rect(rect.x, rect.y, rect.width, rect.height);
    ctx.fill();
    ctx.restore();

    // ctx.font = "18px Arial";
    // ctx.fillStyle = '#000';
    // ctx.fillRect(rect.x, rect.y-20, 50, 20);
    // ctx.fillStyle = '#fff';
    // ctx.fillText("123",rect.x,rect.y-5);
  }

  public static shadeEverythingButRect(
    canvas: HTMLCanvasElement,
    rect: IRect,
    color: string = "rgba(0, 0, 0, 0.7)"
  ): void {
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.save();
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.globalCompositeOperation = "destination-out";
    ctx.fillRect(rect.x, rect.y, rect.width, rect.height);
    ctx.restore();
  }

  public static drawCircleWithFill(
    canvas: HTMLCanvasElement,
    anchorPoint: IPoint,
    radius: number = 2,
    color: string
  ): void {
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.save();
    const startAngleRad = UnitUtil.deg2rad(0);
    const endAngleRad = UnitUtil.deg2rad(360);
    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.arc(
      anchorPoint.x,
      anchorPoint.y,
      radius,
      startAngleRad,
      endAngleRad,
      false
    );
    ctx.fill();
    ctx.restore();
  }

  public static drawCircle(
    canvas: HTMLCanvasElement,
    anchorPoint: IPoint,
    radius: number = 2,
    startAngleDeg: number,
    endAngleDeg: number,
    thickness: number = 20,
    color: string = "#ffffff"
  ): void {
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    const startAngleRad = UnitUtil.deg2rad(startAngleDeg);
    const endAngleRad = UnitUtil.deg2rad(endAngleDeg);
    ctx.save();
    ctx.strokeStyle = color;
    ctx.lineWidth = thickness;
    ctx.beginPath();
    ctx.arc(
      anchorPoint.x,
      anchorPoint.y,
      radius,
      startAngleRad,
      endAngleRad,
      false
    );
    ctx.stroke();
    ctx.restore();
  }

  public static drawPolygon(
    canvas: HTMLCanvasElement,
    anchors: IPoint[],
    color: string = "#fff",
    thickness: number = 1
  ): void {
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.save();
    ctx.strokeStyle = color;
    ctx.lineWidth = thickness;
    ctx.beginPath();
    ctx.moveTo(anchors[0]?.x, anchors[0]?.y);
    for (let i = 1; i < anchors.length; i++) {
      ctx.lineTo(anchors[i].x, anchors[i].y);
    }
    ctx.closePath();
    ctx.stroke();

    ctx.restore();
    // ctx.font = "bold 1rem Montserrat";
    // ctx.fillStyle = "#eeeeee01";
    // ctx.fillText("rfsdfdf",anchors[0].x,anchors[0].y - 8);
    ctx.shadowColor = "#666666";
    ctx.shadowBlur = 7;
  }

  public static drawPolygondoted(
    canvas: HTMLCanvasElement,
    anchors: IPoint[],
    color: string = "#fff",
    thickness: number = 4
  ): void {
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.save();
    ctx.strokeStyle = "#fff";
    ctx.lineWidth = 5;
    ctx.fillStyle="#fff"
    ctx.beginPath();
    // ctx.setLineDash([5,5]);
    ctx.moveTo(anchors[0]?.x, anchors[0]?.y);
    for (let i = 1; i < anchors.length; i++) {
      ctx.lineTo(anchors[i].x, anchors[i].y);
    }
    ctx.closePath();
    const dashOffset = Math.floor(Date.now() / 20) % 20; // Adjust the speed of the animation by changing the divisor
    ctx.setLineDash([6, 6]);
    ctx.lineDashOffset = -dashOffset;
    ctx.stroke();
    // ctx.fill();
    ctx.restore();
    ctx.font = "bold 1rem Montserrat";
    ctx.fillStyle = color;
    // ctx.fillText("rfsdfdf",anchors[0].x,anchors[0].y - 8);
    ctx.shadowColor = "#666666";
    ctx.shadowBlur = 7;
  }
  public static drawPolylineWithFill(
    canvas: HTMLCanvasElement,
    anchors: IPoint[],
    color: string = "#fff"
  ): void {
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.save();
    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.moveTo(anchors[0]?.x, anchors[0]?.y);
    for (let i = 1; i < anchors.length; i++) {
      ctx.lineTo(anchors[i].x, anchors[i].y);
    }
    ctx.closePath();
    ctx.fill();
    ctx.restore();
  }

  public static drawPolyline(
    canvas: HTMLCanvasElement,
    LabelName: string,
    anchors: IPoint[],
    color: string = "#fff",
    thickness: number = 1
  ): void {
    const scale =
      ImageUtil.getSize(EditorModel.image).width /
      ViewPortActions.calculateViewPortContentImageRect().width;
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.save();
    ctx.strokeStyle = color;
    ctx.lineWidth = thickness;
    ctx.beginPath();
    ctx.moveTo(anchors[0]?.x, anchors[0]?.y);
    let c = 0;
    for (let i = 1; i < anchors.length; i++) {
      ctx.lineTo(anchors[i].x, anchors[i].y);
      if (i < anchors.length) {
        // c = (c + Math.sqrt( a*a + b*b ));
        c =
          c +
          Math.hypot(
            anchors[i - 1].x - anchors[i].x,
            anchors[i - 1].y - anchors[i].y
          );
      }
    }
    // ctx.closePath();
    ctx.stroke();
    ctx.restore();
    ctx.font = "bold 1rem Montserrat";
    ctx.fillStyle = color;
    ctx.fillText(
      LabelName + "(L " + round(c * scale) + ")",
      anchors[0].x,
      anchors[0].y - 8
    );
    ctx.shadowColor = "#666666";
    ctx.shadowBlur = 5;
  }
  public static drawPolygonWithFill(
    canvas: HTMLCanvasElement,
    anchors: IPoint[],
    color: string = "#fff"
  ): void {
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.save();
    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.moveTo(anchors[0]?.x, anchors[0]?.y);
    for (let i = 1; i < anchors.length; i++) {
      ctx.lineTo(anchors[i].x, anchors[i].y);
    }
    ctx.closePath();
    ctx.fill();
    ctx.restore();
  }

  public static drawText(
    canvas: HTMLCanvasElement,
    text: string,
    textSize: number,
    anchorPoint: IPoint,
    color: string = "#ffffff",
    bold: boolean = false,
    align: string = "center"
  ): void {
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.save();
    ctx.fillStyle = color;
    ctx.textAlign = align as CanvasTextAlign;
    ctx.textBaseline = "middle";
    ctx.font = (bold ? "bold " : "") + textSize + "px Arial";
    ctx.fillText(text, anchorPoint.x, anchorPoint.y);
    ctx.restore();
  }

  public static hexToRGB(hex: string, alpha: number | null = null): string {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha !== null) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }
}
