export default function DateParse(date) {
  if (!date) return "";
  const d = new Date(date);
  return d.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
}
export  function DateWithTimeParse(date){
  if (!date) return "";
  const d = new Date(date);
  return d.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour:"2-digit",
    minute:"2-digit",
    second:"2-digit",
    hour12:true,
  });
}
