import { getCokkie, logout } from "../views/login/Login";
export default function getDataFromApi(url, method, body, type) {
  const cokkie = getCokkie("jwtToken");
  const myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${cokkie}`);
  myHeaders.append("Access-Control-Max-Age", 600);
  myHeaders.append("Cache-Control", "no-cache")
  var requestOptions = body
    ? {
      method,
      headers: myHeaders,
      body: JSON.stringify(body),
    }
    : {
      method,
      headers: myHeaders,
    };
  return fetch(url, requestOptions).then((response) => {
    if (response.status === 401) {
      logout();
      return;
    }
    else if (response.status === 403) {
      throw new Error("🚫  You are unauthorised to use this action");
    }
    if (type === 'blob') return response.blob();
    if (type === 'text') return response.text();
    return response.json();
  });
}
