import { lazy, Suspense, } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Loader from "./components/Loader";
import TimeSeriesAnnotation from "./views/dashboard/deeplabel/timeseries/TimeSeriesAnnotation";
const Hub  = lazy(() => import("./views/admin/Hub"));
const Login = lazy(() => import("./views/login/Login"));
const Dashboard = lazy(() => import("./views/dashboard/Dashboard"));
const Experiments = lazy(() => import("./views/dashboard/experiments/Experiments"));
const CustomComponents = lazy(() => import("./views/dashboard/custom_components/CustomComponents"));
const PageNotFound = lazy(() => import("./components/PageNotFound"));
const Anotations = lazy(() => import("./Anotations"));
const Project = lazy(() => import("./views/project/Project"));
const DeepLabel = lazy(() => import("./views/dashboard/deeplabel/DeepLabel"));
const DeepDeploy = lazy(() => import("./views/dashboard/DeepDeploy/DeepDeploy"));
const Tutorial = lazy(() => import("./setup/Tutorial"));
const Admin = lazy(() => import("./views/admin/Admin"));
const AdminUserCard = lazy(() => import("./views/admin/AdminUserCard"));
const AdminDashboard = lazy(() => import("./views/admin/AdminDashboard"));
const Subscription = lazy(() => import("./views/admin/Subscription"));
const Logs = lazy(() => import("./views/admin/Logs"));
const DocList = lazy(() => import("./setup/DocList"));
const SolutionsTab =lazy(()=> import("./views/dashboard/DeepDeploy/SolutionsTab"))

function userAuth() {
  try {
    return document.cookie
      .split("; ")
      .find((row) => row.startsWith("jwtToken"))
      .split("=")[1];
  } catch (e) {
    localStorage.clear();
    return false;
  }
}

export default function Router() {
  return (
    <BrowserRouter>
      {/* The  Routes decides which component to show based on the current URL.*/}
      <Routes>
        <Route
          path="/login"
          element={
            !userAuth() ? (
              <Login />
            ) : (
              <Navigate to={`/projects`} replace={true} />
            )
          }
        />

        <Route
          path="/"
          element={
            <Navigate
              to={`/dashboard/${localStorage.getItem("projectId")}/deepdata`}
            />
          }
        />
        <Route path="/projects" element={CheckRoutes(<Project />)} />
        <Route path="/dashboard/:projectId" element={CheckRoutes(<Dashboard />)}>
          <Route path="deepdata" element={CheckRoutes(<Dashboard />)} />
          <Route path="deepdata/:datasetValue" element={CheckRoutes(<Dashboard />)} />
          <Route path="deepLabel" element={CheckRoutes(<DeepLabel />)} />
          <Route path="deepdeploy" element={CheckRoutes(<DeepDeploy />)} />
          <Route path="deepdeploy/:architecture" element={CheckRoutes(<DeepDeploy />)} />
          <Route path="deeptrain" element={CheckRoutes(<Experiments />)} />
          <Route path="customcomponents" element={CheckRoutes(<CustomComponents />)} />
        </Route>
        <Route path="/timeseriesannotation/:dsVersionId" element={<TimeSeriesAnnotation />} />
        <Route path="/annotations" element={CheckRoutes(<Anotations />)} />
        <Route path="/docs/:doctype/:docid" element={CheckRoutes(<Tutorial />)} />
        <Route path="/docs/:doctype" element={CheckRoutes(<Tutorial />)} />
        <Route path="/docs/" element={CheckRoutes(<DocList />)} />
        <Route path="/admin" element={CheckRoutes(<Admin />)}>
          <Route path="users" element={CheckRoutes(<AdminUserCard />)} />
          <Route path="dashboard" element={CheckRoutes(<AdminDashboard />)} />
          <Route path="logs" element={CheckRoutes(<Logs />)} />
          <Route path="subscription" element={CheckRoutes(<Subscription />)} />
        </Route>
        <Route path='/hub' element={CheckRoutes(<Hub />)} />
        <Route path="restricted" element={CheckRoutes(<PageNotFound access={false} />)} />
        <Route path="*" element={CheckRoutes(<PageNotFound />)} />
        <Route path="/solutions" element={CheckRoutes(<SolutionsTab />)} />
      </Routes>
    </BrowserRouter>
  );

}
function CheckRoutes(component) {
  return userAuth() ? <Suspense fallback={< Loader />}> {component}</Suspense > : <Navigate to="/login" replace={true} />;
}