import { SetStateAction, useEffect, useMemo, useRef } from "react";
import { Suspense, useState, lazy } from "react";
import {
  Badge,
  Button,
  Container,
  Row,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import Loader from "../../../../components/Loader";
import useFetch from "../../../../components/useFetch";
import ErrorScreen from "../../../../components/error/ErrorScreen";
import Sidenav from "../../../../components/sidenav/Sidenav";
import "./TimeSeriesAnnotation.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faEyeSlash,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import LabelInput from "./LabelInput";
import AlertComponent from "../../../../components/alert/AlertComponent";
import { useParams } from "react-router-dom";
import getDataFromApi from "../../../../components/getDataFromApi";
import { ReactStrapModal } from "../../../../components/reactsrap_modal/ReactStrapModel";
import Comments from "./Comments";
const Plot = lazy(() => import("react-plotly.js"));
export type ShapeLabel = {
  labelId: string | number | Date;
  label: string | null;
  shape: any;
  size: number;
  isVisible: boolean;
};

export default function TimeSeriesAnnotation() {
  const { dsVersionId } = useParams();
  type LabelData = ShapeLabel[];
  type apiData = {
    data: any;
    error: string;
    loading: boolean;
    refetch: any;
    setData: React.Dispatch<SetStateAction<any>>;
  };
  const [labelData, setLabelData] = useState<LabelData>([]);
  const prevLabel = useRef();
  const { data, error, loading, refetch, setData }: apiData = useFetch(
    `dataset_versions/${dsVersionId}/label_canvas`
  );
  const label = data?.label_map ?? {};
  const [alert, setAlert] = useState<
    { message: string; status: string } | undefined
  >();
  const [formPath, setFormPath] = useState<string | undefined>();
  const [toggleClass, setToggleClass] = useState(false);
  const commentRef = useRef(null);
  const [open, setOpen] = useState();
  const toggle = (id) => {
    if (open === id) {
      setOpen(null);
    } else {
      setOpen(id);
    }
  };
  useEffect(() => {
    if (!data?.labels) return () => setLabelData([]);
    setLabelData(data.labels);

    return () => setLabelData([]);
  }, [data]);

  const Filteredshapes = useMemo(() => {
    return labelData.map((v) => (v.isVisible ? v.shape : null));
  }, [labelData]);
  const groupedLabels = useMemo(() => {
    return labelData.reduce((a, v) => {
      (a[v.label] = a[v.label] || []).push(v);
      return a;
    }, {});
  }, [labelData]);
  function handleVisible(item) {
    let temp = item;
    temp.isVisible = !temp.isVisible;
    setLabelData(labelData.map((v) => (v.labelId === item.labelId ? temp : v)));
  }

  function deleteLabel(item) {
    setLabelData(labelData.filter((v) => v.labelId !== item.labelId));
  }
  function handleCommentOpen() {
    if (!commentRef.current.classList.contains("show")) {
      commentRef.current.classList.add("show");
    }
  }

  function handleClickOutSide(e) {
    if (
      !commentRef.current.contains(e.target) &&
      commentRef.current.classList.contains("show")
    )
      commentRef.current.classList.remove("show");
  }
  async function handleAction(action) {
    const url = `${window["ENV"].API_URL}/view-api/dataset_versions/${dsVersionId}/labels/${action}`;
    return await getDataFromApi(url, "put", labelData)
      .then((res) => {
        if (res.detail) {
          setAlert(res.detail);
        }
        if (res.data)
          setData((prev) => {
            prev.labels = res.data;
            return { ...prev };
          });
      })
      .catch((e) => console.log(e));
  }
  function onSubmit(data, res) {
    handleAction("save").then(() => {
      setAlert(undefined);
      prevLabel.current = undefined;
      refetch();
    });
  }

  if (loading) return <Loader />;
  if (!data || error)
    return (
      <ErrorScreen
        message={"Annotation Data Not Found"}
        sub={undefined}
      ></ErrorScreen>
    );
  return (
    <Suspense fallback={<Loader />}>
      <Container
        fluid={true}
        style={{ userSelect: "none" }}
        onMouseDown={handleClickOutSide}
      >
        <Sidenav />
        <AlertComponent
          alert={alert}
          time={null}
          close={(e) => setAlert(undefined)}
        />
        <Row className={`ps-3${toggleClass ? " collapsed" : ""}`}>
          <span
            className="label-side-button"
            onClick={(e) => setToggleClass(!toggleClass)}
          >
            Labels
          </span>
          <div className="grey-card p-2 d-flex col-left-collapsed">
            <Plot
              data={data?.data}
              className="w-100 align-self-center h-100"
              config={{
                displaylogo: false,
                responsive: true,
                scrollZoom: true,
                modeBarButtonsToRemove: ["lasso2d", "autoScale2d", "toImage"],
                displayModeBar: true,
              }}
              onSelected={(e) => {
                if (!e.points || e.points.length === 0) return;
                const ifExists = labelData.find(
                  (v) => v.labelId === e.points[0].x
                );
                if (ifExists) {
                  setAlert({ message: `Label already exist`, status: "error" });
                  return;
                }
                const tempShape = {
                  type: "rect",
                  xref: "x",
                  yref: "paper",
                  x0: e.points[0].x,
                  y0: 0,
                  x1: e.points.at(-1).x,
                  y1: 1,
                  line: {
                    color:
                      prevLabel.current && data?.label_map[prevLabel.current],
                    // width: 3
                  },
                  // eslint-disable-next-line no-useless-concat
                  fillcolor:
                    prevLabel.current &&
                    data?.label_map[prevLabel.current] + "80",
                };

                setLabelData((prev) => [
                  ...prev,
                  {
                    labelId: e.points[0].x,
                    label: prevLabel.current,
                    size: e.points.length,
                    shape: tempShape,
                    isVisible: true,
                  },
                ]);
              }}
              layout={{
                dragmode: "pan",
                uirevision: "false",
                selectdirection: "h",
                // autosize: true,
                // title: title,
                legend: { orientation: "h", x: 0.5, xanchor: "center" },
                clickmode: "event+select",
                height: window.innerHeight - 112,
                hovermode: "closest",
                hoverdistance: 5,
                xaxis: {
                  showgrid: false,
                  showline: true,
                },
                shapes: Filteredshapes,
                yaxis: {
                  zeroline: false,
                  fixedrange: true,
                  showgrid: false,
                },
              }}
            ></Plot>
          </div>
          <div className="col-right-collapsed">
            <div className="grey-card p-3" style={{ position: "relative" }}>
              <div className="d-flex justify-content-between align-items-center">
                <span className="w-auto"></span>
                <h3>Labels</h3>
                <span>
                  <FontAwesomeIcon
                    icon={faEdit}
                    size="lg"
                    className="ms-3 cursor-pointer"
                    onClick={(e) => setFormPath(`forms/labels/${dsVersionId}`)}
                  />
                  <Badge color="danger" className="comment-badge p-1">
                    {data?.comments?.length}
                  </Badge>
                  <img
                    width={17}
                    src="/ico/discussion.png"
                    alt="Comment"
                    className="mx-3 cursor-pointer"
                    onClick={handleCommentOpen}
                  />
                  <Comments
                    commentRef={commentRef}
                    data={data}
                    setData={setData}
                    dsVersionId={dsVersionId}
                  />
                </span>
              </div>
              <br />
              <div
                style={{ overflowY: "scroll", height: "calc(100vh - 220px)" }}
              >
                {Object.keys(groupedLabels).map((g) => (
                  // @ts-ignore
                  <Accordion open={open} toggle={toggle} key={g}>
                    <AccordionItem>
                      <AccordionHeader targetId={g}>
                        {g}
                        <span
                          className="badge rounded ms-2"
                          style={{ backgroundColor: "red" }}
                        >
                          {groupedLabels[g]?.length ?? 0}
                        </span>
                      </AccordionHeader>
                      <AccordionBody accordionId={g}>
                        {groupedLabels[g].map((v) => (
                          <div
                            className="d-flex justify-content-evenly align-items-center label-list-item"
                            key={v.labelId + ""}
                          >
                            <FontAwesomeIcon
                              icon={v.isVisible ? faEye : faEyeSlash}
                              className="cursor-pointer"
                              style={{ userSelect: "none" }}
                              onClick={(e) => {
                                handleVisible(v);
                              }}
                            />
                            <LabelInput
                              labels={label}
                              setLabelData={setLabelData}
                              currentLabelData={v}
                              prevLabel={prevLabel}
                            />
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="cursor-pointer"
                              onClick={(e) => deleteLabel(v)}
                            />
                          </div>
                        ))}
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                ))}
              </div>
              <div className="d-flex justify-content-center" style={{ gap: 2 }}>
                {data?.status === "annotation" && (
                  <>
                    <Button
                      color="primary"
                      outline
                      onClick={(e) => handleAction("save")}
                    >
                      Save
                    </Button>
                    <Button
                      color="primary"
                      outline
                      onClick={(e) => handleAction("submit")}
                    >
                      Submit
                    </Button>
                  </>
                )}
                {data?.status === "validation" && data?.isValidator && (
                  <>
                    <Button
                      color="primary"
                      outline
                      onClick={(e) => handleAction("save")}
                    >
                      Save
                    </Button>
                    <Button
                      color="primary"
                      outline
                      onClick={(e) => handleAction("reject")}
                    >
                      Reject
                    </Button>
                    <Button
                      color="primary"
                      outline
                      onClick={(e) => handleAction("complete")}
                    >
                      Complete
                    </Button>
                  </>
                )}

                {data?.status === "complete" && data?.isValidator && (
                  <Button
                    color="primary"
                    outline
                    onClick={(e) => handleAction("Redo")}
                  >
                    Redo
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Row>
        <ReactStrapModal
          open={formPath?.length > 0}
          path={formPath}
          close={() => {
            setFormPath(undefined);
          }}
          onSubmit={onSubmit}
          images={[]}
        />
      </Container>
    </Suspense>
  );
}
