import { useRef } from "react";
import "./Comments.scss";
import { Button, Form, FormGroup, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ErrorScreen from "../../../../components/error/ErrorScreen";
import getDataFromApi from "../../../../components/getDataFromApi";
import { DateWithTimeParse } from "../../../../components/DateParse";
export default function Comments({ commentRef, data, setData, dsVersionId }) {
  type comment = {
    id: string;
    userName: string;
    msg: string;
    checked: boolean;
  };
  function handleComment() {
    if (!inputRef.current.value) return;
    let temp = data?.comments ?? [];
    temp = [
      ...temp,
      {
        id: new Date().toISOString(),
        userName: data?.userName,
        msg: inputRef.current.value,
        checked: false,
      },
    ] as comment[];
    const url = `${window["ENV"].API_URL}/view-api/dataset_versions/${dsVersionId}/add_comment`;
    getDataFromApi(url, "put", temp).then((res) =>
      setData({ ...data, comments: temp })
    );

    inputRef.current.value = null;
  }
  function handleDeleteComment(id) {
    const url = `${window["ENV"].API_URL}/view-api/dataset_versions/${dsVersionId}/remove_comment?comment=${id}`;
    getDataFromApi(url, "put").then((res) => {
      if (res?.detail?.status === "success") {
        const temp = data.comments.filter((v) => v.id !== id);
        setData((prev) => ({ ...prev, comments: temp }));
      }
    });

    // setData((prev) => ({ ...prev, comments: temp }));
  }
  const inputRef = useRef(null);
  return (
    <div ref={commentRef} className="ts-comments grey-card py-3">
      <div className="chat-wrapper px-3">
        {!data?.comments?.length && (
          <ErrorScreen message={"No comments Found"} sub={null} />
        )}
        {data?.comments?.map((v) => (
          <div className="chat bg-primary" key={v.id}>
            <div className="">
              <span className="fw-bold me-3">{v.userName}</span>
              <FontAwesomeIcon
                icon={faTrash}
                className="float-right cursor-pointer"
                onClick={(e) => handleDeleteComment(v.id)}
              />
            </div>
            {v.msg}
            <div className="grey-sub d-flex justify-content-end">
              {DateWithTimeParse(v.id)}
            </div>
          </div>
        ))}
      </div>
      <Form
        className="p-3 bg-white"
        style={{ position: "sticky", bottom: -14 }}
      >
        <FormGroup>
          <Input
            innerRef={inputRef}
            placeholder="Enter your Comment"
            type="textarea"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleComment();
              }
            }}
            style={{ minHeight: "60px" }}
          ></Input>
        </FormGroup>
        <Button outline color="primary" onClick={handleComment}>
          Add Comment
        </Button>
      </Form>
    </div>
  );
}
