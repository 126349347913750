import { useEffect, useState } from "react";
import { Alert, Modal, ModalBody, ModalHeader } from "reactstrap";
import { getCokkie } from "../../views/login/Login";
import AlertComponent from "../alert/AlertComponent";
import getDataFromApi from "../getDataFromApi";
import Loader from "../Loader";
import { Theme } from "@rjsf/bootstrap-4";
import { utils, withTheme } from "@rjsf/core";
import ErrorScreen from "../error/ErrorScreen";
import { CustomWidget } from "../widgets/CustomWidgets";
import "./ReactStrapModel.scss"
export function ReactStrapModal({ open, close, path, onSubmit, images}) {
  const [data, setData] = useState();
  const [alert, setAlert] = useState();
  const [formData, setFormData] = useState();
  const url = `${window["ENV"].API_URL}/view-api/${path}`;
    const uiSchema = {

  };
  const registry = utils.getDefaultRegistry();
  const defaultFileWidget = registry.widgets["FileWidget"];
  Theme.widgets["FileWidget"] = defaultFileWidget;
  const Form = withTheme(Theme);
  
  useEffect(() => {
    open === true &&
      getDataFromApi(url, "get")
        .then((r) => {
          console.log(r);  
          
          if (r === "Contact your organisation admin to add a new user") {
            setAlert({
              message: "Contact your organisation admin to add a new user",
            });
            close(!open);
          } 
          // else if(r.detail === "You are unauthorised to use this action"){
          //   setAlert({
          //     message: "Contact your organisation admin to add a new user",
          //   });
          //   close(!open);

          // }
          else {
            const temp = r;
            temp.json_schema.title = "";
            setData(temp);
          }
        })
        .catch((e) => {
          setAlert({ message: e.message, status: "error" });
          //close(!open);
          const timeoutId = setTimeout(() => {
            setAlert(null);
            close(!open);
          }, 2000);

          return () => clearTimeout(timeoutId);
          
        });
  }, [close, open, url]);
  return (
    <>
      {alert?.status === "success" && <AlertComponent alert={alert} />}
      {alert?.status === "error" && (
        <Alert
          color={"warning"}
          className="mb-0 shadow d-flex"
          isOpen={alert?.message}
          toggle={false} 
          style={{
            width: "333px",
            borderRadius: 10,
            position: "fixed",
            top: "10%",  // Center vertically
            left: "50%",  // Center horizontally
            transform: "translate(-50%, -50%)",  // Center the alert
            zIndex: "999",
          }}
        >
          {alert?.message}
        </Alert>
      )}
      {data && (
        <Modal
          centered
          scrollable
          size="md"
          isOpen={open}
        // toggle={() => {
        //   close(!open);
        // }}
        >
          <ModalHeader
            className="m-0 px-5"
            toggle={() => {
              setAlert();
              setFormData();
              close(!open);
            }}
            style={{ borderBottom: "none" }}
          >
            {path && path.split("/")[1].replace("_", " ")}
          </ModalHeader>
          <ModalBody className="px-5 pb-5" >
            {alert?.status === "error" && (
              <Alert
                // eslint-disable-next-line eqeqeq
                color={"danger"}
                className="mb-0 shadow d-flex"
                isOpen={alert?.message}
                toggle={() => {
                  setAlert();
                }}
                style={{
                  width: "380px",
                  borderRadius: 10,
                  position: "fixed",
                  top: 30,
                  zIndex: "999",
                }}
              >
                {alert?.message}
              </Alert>
            )}
            {data.json_schema ? (
              <Form
                schema={data.json_schema}
                uiSchema={data?.ui_schema ?? uiSchema}
                formData={images?.length > 0 ? {images:images,...formData} : formData}
                onSubmit={({ formData }, e) => {
                  e.preventDefault();
                  setFormData(formData);
                  formSave(url, "post", formData, setAlert, close, onSubmit);
                                    }}
                widgets={CustomWidget}
              // showErrorList={false}
              />
            ) : data?.detail ? (
              <ErrorScreen message={data?.detail ?? "No Form Found"} />
            ) : (
              <Loader />
            )}
          </ModalBody>
        </Modal>
      )}
    </>
  );
}
export const formSave = async (
  url,
  method,
  formData,
  setAlert,
  close,
  onSubmit
) => {
  let cokkie = getCokkie("jwtToken");
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${cokkie}`);
  var urlencoded = JSON.stringify(formData);
  var requestOptions2 = {
    method,
    headers: myHeaders,
    body: urlencoded,
  };
  try {
    const response = await fetch(url, requestOptions2);
    const result = await response.json();
    setAlert(result?.detail);
    // console.log(result.detail)
    if (response.ok) {
      onSubmit && onSubmit(formData, result);
      close(false);
    }
    if(result.presigned_url){

      setAlert(result.detail);
      const link = document.createElement("a");
        Object.assign(link, {
          href: result.presigned_url,
          target: "_blank",
          rel: "noreferrer"
        });
        link.click();
        link.remove();
    }
  } catch (error) {
    setAlert(error.message + " or Internal Server Error");
  }
};
