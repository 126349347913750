import { LabelsActionTypes, ImageData, LabelName, TagName } from "./types";
import { Action } from "../Actions";
import { LabelType } from "../../data/enums/LabelType";
import { LabelsSelector } from "../selectors/LabelsSelector";

export function updateActiveImageIndex(
  activeImageIndex: number
): LabelsActionTypes {
  return {
    type: Action.UPDATE_ACTIVE_IMAGE_INDEX,
    payload: {
      activeImageIndex,
    },
  };
}

export function updateActiveLabelNameId(
  activeLabelNameId: string
): LabelsActionTypes {
  return {
    type: Action.UPDATE_ACTIVE_LABEL_NAME_ID,
    payload: {
      activeLabelNameId,
    },
  };
}

export function updateActiveLabelId(activeLabelId: string): LabelsActionTypes {
  return {
    type: Action.UPDATE_ACTIVE_LABEL_ID,
    payload: {
      activeLabelId,
    },
  };
}

export function updateHighlightedLabelId(
  highlightedLabelId: string
): LabelsActionTypes {
  return {
    type: Action.UPDATE_HIGHLIGHTED_LABEL_ID,
    payload: {
      highlightedLabelId,
    },
  };
}

export function updateActiveLabelType(
  activeLabelType: LabelType
): LabelsActionTypes {
  return {
    type: Action.UPDATE_ACTIVE_LABEL_TYPE,
    payload: {
      activeLabelType,
    },
  };
}
export function updateImageData(imageData: ImageData[]): LabelsActionTypes {
  return {
    type: Action.UPDATE_IMAGES_DATA,
    payload: {
      imageData,
    },
  };
}



// export function updateImageDataById(
//   id: string,
//   newImageData: ImageData
// ): LabelsActionTypes {
//   const undoRedo: [] = JSON.parse(localStorage.getItem(`undoRedo_${newImageData.image_id}`)) ?? [];
//   const a = [newImageData, ...undoR  edo.slice(JSON.parse(localStorage.getItem(`undoindex_${newImageData.image_id}`)), 19)]
//   localStorage.setItem(`undoRedo_${newImageData.image_id}`, JSON.stringify(a))
//   localStorage.setItem(`undoindex_${newImageData.image_id}`, JSON.stringify(0))
//   return {
//     type: Action.UPDATE_IMAGE_DATA_BY_ID,
//     payload: {
//       id,
//       newImageData,
//     },
//   };
// }

// export function updateImageDataById(
//   id: string,
//   newImageData: ImageData
// ): LabelsActionTypes {
//   const undoRedo: ImageData[] = JSON.parse(localStorage.getItem(`undoRedo_${newImageData.image_id}`)) ?? [];
//   const latestEntry = undoRedo.length > 0 ? undoRedo[0] : null;
//   if (latestEntry && JSON.stringify(latestEntry) === JSON.stringify(newImageData)) {
//     // console.log('Entry is the same as the latest one, not storing it.');
//   } else {
//     const updatedUndoRedo = [newImageData, ...undoRedo.slice(JSON.parse(localStorage.getItem(`undoindex_${newImageData.image_id}`)), 19)];
//     localStorage.setItem(`undoRedo_${newImageData.image_id}`, JSON.stringify(updatedUndoRedo));
//     localStorage.setItem(`undoindex_${newImageData.image_id}`, JSON.stringify(0));
//     // console.log('Entry is different from the latest one, so storing it.');
//   }

//   return {
//     type: Action.UPDATE_IMAGE_DATA_BY_ID,
//     payload: {
//       id,
//       newImageData,
//     },
//   };
// }



export function updateImageDataById(
  id: string,
  newImageData: ImageData
): LabelsActionTypes {
  const storageKey = `undoRedo_${newImageData.image_id}`;
  const currentData = localStorage.getItem(storageKey);
  let undoRedo: ImageData[] = currentData ? JSON.parse(currentData) : [];
  const latestEntry = undoRedo.length > 0 ? undoRedo[0] : null;

  if (!latestEntry || JSON.stringify(latestEntry) !== JSON.stringify(newImageData)) {
    const undoIndex = JSON.parse(localStorage.getItem(`undoindex_${newImageData.image_id}`)) || 0;
    const updatedUndoRedo = [newImageData, ...undoRedo.slice(undoIndex, undoIndex + 18)];
    localStorage.setItem(storageKey, JSON.stringify(updatedUndoRedo));
    localStorage.setItem(`undoindex_${newImageData.image_id}`, JSON.stringify(0));
  }
  let imageIds = JSON.parse(localStorage.getItem('imageIds')) ?? [];
  imageIds = imageIds.filter(imageId => imageId !== newImageData.image_id);
  imageIds.unshift(newImageData.image_id);
    if (imageIds.length > 2) {
      const removedImageId = imageIds.pop();
      localStorage.removeItem(`undoRedo_${removedImageId}`);
      localStorage.removeItem(`undoindex_${removedImageId}`);
    }
  localStorage.setItem('imageIds', JSON.stringify(imageIds));

  const allImageIds = Object.keys(localStorage)
  .filter(key => key.startsWith('undoRedo_'))
  .map(key => key.replace('undoRedo_', ''));

allImageIds.forEach(id => {
  if (!imageIds.includes(id)) {
    localStorage.removeItem(`undoRedo_${id}`);
    localStorage.removeItem(`undoindex_${id}`);
  }
});

  return {
    type: Action.UPDATE_IMAGE_DATA_BY_ID,
    payload: {
      id,
      newImageData,
    },
  };
}


export function updateImageDataByIdwithoutundo(
  id: string,
  newImageData: ImageData
): LabelsActionTypes {
  // const undoRedo: [] = JSON.parse(localStorage.getItem("undoRedo")) ?? [];
  // const a = [newImageData, ...undoRedo.slice(JSON.parse(localStorage.getItem("undoindex")), 19)]
  // localStorage.setItem("undoRedo", JSON.stringify(a))
  // localStorage.setItem("undoindex", JSON.stringify(0))
  return {
    type: Action.UPDATE_IMAGE_DATA_BY_ID,
    payload: {
      id,
      newImageData,
    },
  };
}

export function undoRedo(
  id: string,
  newImageData: ImageData
): LabelsActionTypes {
  // window.alert("yes")
  return {
    type: Action.UPDATE_IMAGE_DATA_BY_ID,
    payload: {
      id,
      newImageData,
    },

  };
}
export function addImageData(imageData: ImageData[]): LabelsActionTypes {
  return {
    type: Action.ADD_IMAGES_DATA,
    payload: {
      imageData,
    },
  };
}



export function updateLabelNames(labels: LabelName[]): LabelsActionTypes {
  return {
    type: Action.UPDATE_LABEL_NAMES,
    payload: {
      labels,
    },
  };
}
export function updateTagNames(tags: TagName[]): LabelsActionTypes {
  return {
    type: Action.UPDATE_TAG_NAMES,
    payload: {
      tags,
    },
  };
}

export function updateFirstLabelCreatedFlag(
  firstLabelCreatedFlag: boolean
): LabelsActionTypes {
  return {
    type: Action.UPDATE_FIRST_LABEL_CREATED_FLAG,
    payload: {
      firstLabelCreatedFlag,
    },
  };
}
