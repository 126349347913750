import React from "react";

import "./Loader.scss";
export default function Loader() {
  return (
    <div className="w-100 d-flex justify-content-center align-items-center" style={{ overflowX: "hidden", zIndex: 999 }}>
      <div
        style={{
          padding: "20px",
          // margin: "10px 47%",
          // display: "inline-flex",
        }}
      >
        {/* <Spinner color="primary" type="grow" className=" m-1" />
        <Spinner color="primary" type="grow" className=" m-1" />
        <Spinner color="primary" type="grow" className=" m-1" /> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="56"
          viewBox="0 0 31 28"
          fill="none"
        >
          <path
            d="M23 3h-9C7.925 3 3 7.925 3 14v0c0 6.075 4.925 11 11 11h9M21 14h7"
            stroke="#3755d3"
            className="path"
            strokeWidth="6"
            strokeLinecap="round"
          ></path>
        </svg>
      </div>
    </div>
  );
}
