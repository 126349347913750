import { store } from "../../index";
import { RectUtil } from "../../utils/RectUtil";
import { updateCustomCursorStyle } from "../../store/general/actionCreators";
import { CustomCursorStyle } from "../../data/enums/CustomCursorStyle";
import { EditorData } from "../../data/EditorData";
import { BaseRenderEngine } from "./BaseRenderEngine";
import { RenderEngineSettings } from "../../settings/RenderEngineSettings";
import { IPoint } from "../../interfaces/IPoint";
import { ILine } from "../../interfaces/ILine";
import { DrawUtil } from "../../utils/DrawUtil";
import { IRect } from "../../interfaces/IRect";
import { ImageData, LabelPolyline } from "../../store/labels/types";
import { LabelsSelector } from "../../store/selectors/LabelsSelector";
import {
  updateActiveLabelId,
  updateActiveLabelNameId,
  updateFirstLabelCreatedFlag,
  updateHighlightedLabelId,
  updateImageDataById,
} from "../../store/labels/actionCreators";
import { LineUtil } from "../../utils/LineUtil";
import { MouseEventUtil } from "../../utils/MouseEventUtil";
import { EventType } from "../../data/enums/EventType";
import { RenderEngineUtil } from "../../utils/RenderEngineUtil";
import { LabelType } from "../../data/enums/LabelType";
import { EditorActions } from "../actions/EditorActions";
import { GeneralSelector } from "../../store/selectors/GeneralSelector";
import { Settings } from "../../settings/Settings";
import { LabelUtil } from "../../utils/LabelUtil";

export class PolylineRenderEngine extends BaseRenderEngine {
  // =================================================================================================================
  // STATE
  // =================================================================================================================

  private activePath: IPoint[] = [];
  private resizeAnchorIndex: number = null;
  private suggestedAnchorPositionOnCanvas: IPoint = null;
  private suggestedAnchorIndexInpolyline: number = null;

  public constructor(canvas: HTMLCanvasElement) {
    super(canvas);
    this.labelType = LabelType.POLYLINE;
  }

  // =================================================================================================================
  // EVENT HANDLERS
  // =================================================================================================================

  public update(data: EditorData): void {
    if (!!data.event) {
      switch (MouseEventUtil.getEventType(data.event)) {
        case EventType.MOUSE_MOVE:
          this.mouseMoveHandler(data);
          break;
        case EventType.MOUSE_UP:
          this.mouseUpHandler(data);
          break;
        case EventType.MOUSE_DOWN:
          this.mouseDownHandler(data);
          break;
        default:
          break;
      }
    }
  }

  public mouseDownHandler(data: EditorData): void {
    const isMouseOverCanvas: boolean = RenderEngineUtil.isMouseOverCanvas(data);
    if (isMouseOverCanvas) {
      if (this.isCreationInProgress()) {
        const isMouseOverStartAnchor: boolean =
          RenderEngineUtil.isMouseOverAnchor(
            data.mousePositionOnViewPortContent,
            this.activePath[0],
            RenderEngineSettings.anchorSize
          );
        if (isMouseOverStartAnchor) {
          this.addLabelAndFinishCreation(data);
        } else {
          this.updateActivelyCreatedLabel(data);
        }
      } else {
        const polylineUnderMouse: LabelPolyline =
          this.getpolylineUnderMouse(data);
        if (!!polylineUnderMouse) {
          const anchorIndex: number = polylineUnderMouse.vertices.reduce(
            (indexUnderMouse: number, anchor: IPoint, index: number) => {
              if (indexUnderMouse === null) {
                const anchorOnCanvas: IPoint =
                  RenderEngineUtil.transferPointFromImageToViewPortContent(
                    anchor,
                    data
                  );
                if (
                  this.isMouseOverAnchor(
                    data.mousePositionOnViewPortContent,
                    anchorOnCanvas
                  )
                ) {
                  return index;
                }
              }
              return indexUnderMouse;
            },
            null
          );

          if (anchorIndex !== null) {
            this.startExistingLabelResize(
              data,
              polylineUnderMouse.id,
              anchorIndex
            );
          } else {
            store.dispatch(updateActiveLabelId(polylineUnderMouse.id));
            const isMouseOverNewAnchor: boolean = this.isMouseOverAnchor(
              data.mousePositionOnViewPortContent,
              this.suggestedAnchorPositionOnCanvas
            );
            if (isMouseOverNewAnchor) {
              this.addSuggestedAnchorTopolylineLabel(data);
            }
          }
        } else {
          this.updateActivelyCreatedLabel(data);
        }
      }
    }
  }

  public mouseUpHandler(data: EditorData): void {
    if (this.isResizeInProgress()) this.endExistingLabelResize(data);
  }

  public mouseMoveHandler(data: EditorData): void {
    if (
      !!data.viewPortContentImageRect &&
      !!data.mousePositionOnViewPortContent
    ) {
      const isOverImage: boolean = RenderEngineUtil.isMouseOverImage(data);
      if (isOverImage && !this.isCreationInProgress()) {
        const labelpolyline: LabelPolyline = this.getpolylineUnderMouse(data);
        if (!!labelpolyline && !this.isResizeInProgress()) {
          if (LabelsSelector.getHighlightedLabelId() !== labelpolyline.id) {
            store.dispatch(updateHighlightedLabelId(labelpolyline.id));
          }
          const pathOnCanvas: IPoint[] =
            RenderEngineUtil.transferPolylineFromImageToViewPortContent(
              labelpolyline.vertices,
              data
            );
          const linesOnCanvas: ILine[] = this.mapPointsToLines(
            pathOnCanvas.concat(pathOnCanvas[0])
          );

          for (let j = 0; j < linesOnCanvas.length; j++) {
            const mouseOverLine = RenderEngineUtil.isMouseOverLine(
              data.mousePositionOnViewPortContent,
              linesOnCanvas[j],
              RenderEngineSettings.anchorHoverSize.width / 2
            );
            if (mouseOverLine) {
              this.suggestedAnchorPositionOnCanvas = LineUtil.getCenter(
                linesOnCanvas[j]
              );
              this.suggestedAnchorIndexInpolyline = j + 1;
              break;
            }
          }
        } else {
          if (LabelsSelector.getHighlightedLabelId() !== null) {
            store.dispatch(updateHighlightedLabelId(null));
            this.discardSuggestedPoint();
          }
        }
      }
    }
  }
  public pasteHandler(): void {
        this.imageDataCache.forEach((polyLabel) => {
            store.dispatch(updateActiveLabelNameId(polyLabel.labelId));
            this.addpolylineLabel(polyLabel.vertices);
        });
}
  // =================================================================================================================
  // RENDERING
  // =================================================================================================================

  public render(data: EditorData): void {
    const imageData: ImageData = LabelsSelector.getActiveImageData();
    if (imageData) {
      this.drawExistingLabels(data);
      this.drawActivelyCreatedLabel(data);
      this.drawActivelyResizeLabel(data);
      this.updateCursorStyle(data);
      this.drawSuggestedAnchor(data);
    }
  }

  private updateCursorStyle(data: EditorData) {
    if (
      !!this.canvas &&
      !!data.mousePositionOnViewPortContent &&
      !GeneralSelector.getImageDragModeStatus()
    ) {
      const isMouseOverCanvas: boolean =
        RenderEngineUtil.isMouseOverCanvas(data);
      if (isMouseOverCanvas) {
        if (this.isCreationInProgress()) {
          const isMouseOverStartAnchor: boolean = this.isMouseOverAnchor(
            data.mousePositionOnViewPortContent,
            this.activePath[0]
          );
          if (isMouseOverStartAnchor && this.activePath.length > 2)
            store.dispatch(updateCustomCursorStyle(CustomCursorStyle.CLOSE));
          else
            store.dispatch(updateCustomCursorStyle(CustomCursorStyle.DEFAULT));
        } else {
          const anchorUnderMouse: IPoint = this.getAnchorUnderMouse(data);
          const isMouseOverNewAnchor: boolean = this.isMouseOverAnchor(
            data.mousePositionOnViewPortContent,
            this.suggestedAnchorPositionOnCanvas
          );
          if (!!isMouseOverNewAnchor) {
            store.dispatch(updateCustomCursorStyle(CustomCursorStyle.ADD));
          } else if (this.isResizeInProgress()) {
            store.dispatch(updateCustomCursorStyle(CustomCursorStyle.MOVE));
          } else if (!!anchorUnderMouse) {
            store.dispatch(updateCustomCursorStyle(CustomCursorStyle.MOVE));
          } else {
            RenderEngineUtil.wrapDefaultCursorStyleInCancel(data);
          }
        }
        this.canvas.style.cursor = "none";
      } else {
        this.canvas.style.cursor = "default";
      }
    }
  }

  private drawActivelyCreatedLabel(data: EditorData) {
    const standardizedPoints: IPoint[] = this.activePath.map((point: IPoint) =>
      RenderEngineUtil.setPointBetweenPixels(point)
    );
    const path = standardizedPoints.concat(data.mousePositionOnViewPortContent);
    const lines: ILine[] = this.mapPointsToLines(path);
    const LabelName: string = BaseRenderEngine.resolveLabelLineName(null);
    const lineColor: string = BaseRenderEngine.resolveLabelLineColor(
      null,
      true
    );
    const anchorColor: string = BaseRenderEngine.resolveLabelAnchorColor(true);
    DrawUtil.drawPolyline(
      this.canvas,
      LabelName,
      path,
      DrawUtil.hexToRGB(lineColor, 0.2)
    );
    lines.forEach((line: ILine) => {
      DrawUtil.drawLine(
        this.canvas,
        line.start,
        line.end,
        lineColor,
        RenderEngineSettings.LINE_THICKNESS
      );
    });
    standardizedPoints.forEach((point: IPoint) => {
      DrawUtil.drawCircleWithFill(
        this.canvas,
        point,
        Settings.RESIZE_HANDLE_DIMENSION_PX / 2,
        anchorColor
      );
    });
  }

  private drawActivelyResizeLabel(data: EditorData) {
    const activeLabelpolyline: LabelPolyline =
      LabelsSelector.getActivePolylineLabel();
    if (!!activeLabelpolyline && this.isResizeInProgress()) {
      const snappedMousePosition: IPoint = RectUtil.snapPointToRect(
        data.mousePositionOnViewPortContent,
        data.viewPortContentImageRect
      );
      const polylineOnCanvas: IPoint[] = activeLabelpolyline.vertices.map(
        (point: IPoint, index: number) => {
          return index === this.resizeAnchorIndex
            ? snappedMousePosition
            : RenderEngineUtil.transferPointFromImageToViewPortContent(
                point,
                data
              );
        }
      );
      this.drawPolyline(activeLabelpolyline.labelId, polylineOnCanvas, true);
    }
  }

  private drawExistingLabels(data: EditorData) {
    const activeLabelId: string = LabelsSelector.getActiveLabelId();
    const highlightedLabelId: string = LabelsSelector.getHighlightedLabelId();
    const imageData: ImageData = LabelsSelector.getActiveImageData();
    imageData.labelPolylines.forEach((labelpolyline: LabelPolyline) => {
      const isActive: boolean =
        labelpolyline.id === activeLabelId ||
        labelpolyline.id === highlightedLabelId;
      const pathOnCanvas: IPoint[] =
        RenderEngineUtil.transferPolylineFromImageToViewPortContent(
          labelpolyline.vertices,
          data
        );
      if (!(labelpolyline.id === activeLabelId && this.isResizeInProgress())) {
        this.drawPolyline(labelpolyline.labelId, pathOnCanvas, isActive);
      }
    });
  }

  private drawPolyline(
    labelId: string | null,
    polyline: IPoint[],
    isActive: boolean
  ) {
    const lineColor: string = BaseRenderEngine.resolveLabelLineColor(
      labelId,
      true
    );

    const LabelName: string = BaseRenderEngine.resolveLabelLineName(labelId);
    const anchorColor: string = BaseRenderEngine.resolveLabelAnchorColor(true);
    const standardizedPoints: IPoint[] = polyline.map((point: IPoint) =>
      RenderEngineUtil.setPointBetweenPixels(point)
    );
    if (isActive) {
      DrawUtil.drawPolyline(
        this.canvas,
        LabelName,
        standardizedPoints,
        DrawUtil.hexToRGB(lineColor, 0.2)
      );
    }
    DrawUtil.drawPolyline(
      this.canvas,
      LabelName,
      standardizedPoints,
      lineColor,
      RenderEngineSettings.LINE_THICKNESS
    );
    if (isActive) {
      standardizedPoints.forEach((point: IPoint) => {
        DrawUtil.drawCircleWithFill(
          this.canvas,
          point,
          Settings.RESIZE_HANDLE_DIMENSION_PX / 2,
          anchorColor
        );
      });
    }
  }

  private drawSuggestedAnchor(data: EditorData) {
    const anchorColor: string = BaseRenderEngine.resolveLabelAnchorColor(true);
    if (this.suggestedAnchorPositionOnCanvas) {
      const suggestedAnchorRect: IRect = RectUtil.getRectWithCenterAndSize(
        this.suggestedAnchorPositionOnCanvas,
        RenderEngineSettings.suggestedAnchorDetectionSize
      );
      const isMouseOverSuggestedAnchor: boolean = RectUtil.isPointInside(
        suggestedAnchorRect,
        data.mousePositionOnViewPortContent
      );

      if (isMouseOverSuggestedAnchor) {
        DrawUtil.drawCircleWithFill(
          this.canvas,
          this.suggestedAnchorPositionOnCanvas,
          Settings.RESIZE_HANDLE_DIMENSION_PX / 2,
          anchorColor
        );
      }
    }
  }

  // =================================================================================================================
  // CREATION
  // =================================================================================================================

  private updateActivelyCreatedLabel(data: EditorData) {
    if (this.isCreationInProgress()) {
      const mousePositionSnapped: IPoint = RectUtil.snapPointToRect(
        data.mousePositionOnViewPortContent,
        data.viewPortContentImageRect
      );
      this.activePath.push(mousePositionSnapped);
    } else {
      const isMouseOverImage: boolean = RectUtil.isPointInside(
        data.viewPortContentImageRect,
        data.mousePositionOnViewPortContent
      );
      if (isMouseOverImage) {
        EditorActions.setViewPortActionsDisabledStatus(true);
        this.activePath.push(data.mousePositionOnViewPortContent);
        store.dispatch(updateActiveLabelId(null));
      }
    }
  }

  public cancelLabelCreation() {
    this.activePath = [];
    EditorActions.setViewPortActionsDisabledStatus(false);
  }

  private finishLabelCreation() {
    this.activePath = [];
    EditorActions.setViewPortActionsDisabledStatus(false);
  }

  public addLabelAndFinishCreation(data: EditorData) {
    if (this.isCreationInProgress() && this.activePath.length > 2) {
      const polylineOnImage: IPoint[] =
        RenderEngineUtil.transferPolylineFromViewPortContentToImage(
          this.activePath,
          data
        );
      this.addpolylineLabel(polylineOnImage);
      this.finishLabelCreation();
    }
  }

  private addpolylineLabel(polyline: IPoint[]) {
    const activeLabelId = LabelsSelector.getActiveLabelNameId();
    const imageData: ImageData = LabelsSelector.getActiveImageData();
    const labelpolyline: LabelPolyline = LabelUtil.createLabelPolyline(
      activeLabelId,
      polyline
    );
    imageData.labelPolylines.push(labelpolyline);
    store.dispatch(updateImageDataById(imageData.image_id, imageData));
    store.dispatch(updateFirstLabelCreatedFlag(true));
    store.dispatch(updateActiveLabelId(labelpolyline.id));
  }

  // =================================================================================================================
  // TRANSFER
  // =================================================================================================================

  private startExistingLabelResize(
    data: EditorData,
    labelId: string,
    anchorIndex: number
  ) {
    store.dispatch(updateActiveLabelId(labelId));
    this.resizeAnchorIndex = anchorIndex;
    EditorActions.setViewPortActionsDisabledStatus(true);
  }

  private endExistingLabelResize(data: EditorData) {
    this.applyResizeTopolylineLabel(data);
    this.resizeAnchorIndex = null;
    EditorActions.setViewPortActionsDisabledStatus(false);
  }

  private applyResizeTopolylineLabel(data: EditorData) {
    const imageData: ImageData = LabelsSelector.getActiveImageData();
    const activeLabel: LabelPolyline = LabelsSelector.getActivePolylineLabel();
    imageData.labelPolylines = imageData.labelPolylines.map(
      (polyline: LabelPolyline) => {
        if (polyline.id !== activeLabel.id) {
          return polyline;
        } else {
          return {
            ...polyline,
            vertices: polyline.vertices.map((value: IPoint, index: number) => {
              if (index !== this.resizeAnchorIndex) {
                return value;
              } else {
                const snappedMousePosition: IPoint = RectUtil.snapPointToRect(
                  data.mousePositionOnViewPortContent,
                  data.viewPortContentImageRect
                );
                return RenderEngineUtil.transferPointFromViewPortContentToImage(
                  snappedMousePosition,
                  data
                );
              }
            }),
          };
        }
      }
    );
    store.dispatch(updateImageDataById(imageData.image_id, imageData));
    store.dispatch(updateActiveLabelId(activeLabel.id));
  }

  private discardSuggestedPoint(): void {
    this.suggestedAnchorIndexInpolyline = null;
    this.suggestedAnchorPositionOnCanvas = null;
  }

  // =================================================================================================================
  // UPDATE
  // =================================================================================================================

  private addSuggestedAnchorTopolylineLabel(data: EditorData) {
    const imageData: ImageData = LabelsSelector.getActiveImageData();
    const activeLabel: LabelPolyline = LabelsSelector.getActivePolylineLabel();
    const newAnchorPositionOnImage: IPoint =
      RenderEngineUtil.transferPointFromViewPortContentToImage(
        this.suggestedAnchorPositionOnCanvas,
        data
      );
    const insert = (arr, index, newItem) => [
      ...arr.slice(0, index),
      newItem,
      ...arr.slice(index),
    ];

    const newImageData: ImageData = {
      ...imageData,
      labelPolylines: imageData.labelPolylines.map(
        (polyline: LabelPolyline) => {
          if (polyline.id !== activeLabel.id) {
            return polyline;
          } else {
            return {
              ...polyline,
              vertices: insert(
                polyline.vertices,
                this.suggestedAnchorIndexInpolyline,
                newAnchorPositionOnImage
              ),
            };
          }
        }
      ),
    };

    store.dispatch(updateImageDataById(newImageData.image_id, newImageData));
    this.startExistingLabelResize(
      data,
      activeLabel.id,
      this.suggestedAnchorIndexInpolyline
    );
    this.discardSuggestedPoint();
  }

  // =================================================================================================================
  // VALIDATORS
  // =================================================================================================================

  public isInProgress(): boolean {
    return this.isCreationInProgress() || this.isResizeInProgress();
  }

  private isCreationInProgress(): boolean {
    return this.activePath !== null && this.activePath.length !== 0;
  }

  private isResizeInProgress(): boolean {
    return this.resizeAnchorIndex !== null;
  }

  private isMouseOverAnchor(mouse: IPoint, anchor: IPoint): boolean {
    if (!mouse || !anchor) return null;
    return RectUtil.isPointInside(
      RectUtil.getRectWithCenterAndSize(
        anchor,
        RenderEngineSettings.anchorSize
      ),
      mouse
    );
  }

  // =================================================================================================================
  // MAPPERS
  // =================================================================================================================

  private mapPointsToLines(points: IPoint[]): ILine[] {
    const lines: ILine[] = [];
    for (let i = 0; i < points.length - 1; i++) {
      lines.push({ start: points[i], end: points[i + 1] });
    }
    return lines;
  }

  // =================================================================================================================
  // GETTERS
  // =================================================================================================================

  private getpolylineUnderMouse(data: EditorData): LabelPolyline {
    const labelpolylines: LabelPolyline[] =
      LabelsSelector.getActiveImageData().labelPolylines;
    for (let i = 0; i < labelpolylines.length; i++) {
      const pathOnCanvas: IPoint[] =
        RenderEngineUtil.transferPolylineFromImageToViewPortContent(
          labelpolylines[i].vertices,
          data
        );
      const linesOnCanvas: ILine[] = this.mapPointsToLines(
        pathOnCanvas.concat(pathOnCanvas[0])
      );

      for (let j = 0; j < linesOnCanvas.length; j++) {
        const mouseOverLine = RenderEngineUtil.isMouseOverLine(
          data.mousePositionOnViewPortContent,
          linesOnCanvas[j],
          RenderEngineSettings.anchorHoverSize.width / 2
        );
        if (mouseOverLine) return labelpolylines[i];
      }
      for (let j = 0; j < pathOnCanvas.length; j++) {
        if (
          this.isMouseOverAnchor(
            data.mousePositionOnViewPortContent,
            pathOnCanvas[j]
          )
        )
          return labelpolylines[i];
      }
    }
    return null;
  }

  private getAnchorUnderMouse(data: EditorData): IPoint {
    const labelpolylines: LabelPolyline[] =
      LabelsSelector.getActiveImageData().labelPolylines;
    for (let i = 0; i < labelpolylines.length; i++) {
      const pathOnCanvas: IPoint[] =
        RenderEngineUtil.transferPolylineFromImageToViewPortContent(
          labelpolylines[i].vertices,
          data
        );
      for (let j = 0; j < pathOnCanvas.length; j++) {
        if (
          this.isMouseOverAnchor(
            data.mousePositionOnViewPortContent,
            pathOnCanvas[j]
          )
        )
          return pathOnCanvas[j];
      }
    }
    return null;
  }
}
