import { useEffect, useState } from "react";
import { Alert } from "reactstrap";
export default function AlertComponent({ alert, time, close }) {
  const [data, setData] = useState();
  useEffect(() => {
    setData(alert?.message);
    return () => setData();
  }, [alert]);
  useEffect(() => {
    if (data) {
      let timeout = setTimeout(() => {
        setData();
        close && close();
      }, time ?? 5000);
      return () => {
        setData();
        clearTimeout(timeout);
      };
    }
  }, [close, data, time]);
  if (!data) return null;
  return (
    <div className="global-alert">
      <Alert
        // eslint-disable-next-line eqeqeq
        color={alert?.status == "error" ? "danger" : "success"}
        className="mb-0 shadow d-flex"
        isOpen={data?.message}
        toggle={() => {
          setData();
          close && close();
        }}
        style={{ width: "100%", borderRadius: 10 }}
      >
        {data}
      </Alert>
    </div>
  );
}
