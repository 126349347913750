import { store } from "../..";
import {
  ImageData,
  LabelLine,
  LabelName,
  LabelPoint,
  LabelPolygon,
  LabelPolyline,
  LabelRect,
} from "../labels/types";
import { find } from "lodash";
import { LabelType } from "../../data/enums/LabelType";

export class LabelsSelector {
  public static getLabelNames(): LabelName[] {
    // console.log(store.getState().labels);
    return store.getState().labels.labels;
    // return [{"id":"fc9d627a-3ff5-466f-ab9f-57f41c7abd5d","name":"dofg","color":"#3ddb86"}];
  }

  public static getLabelNameById(id: string): LabelName | undefined {
    const labelName: LabelName[] = LabelsSelector.getLabelNames();
    return find(labelName, { id });
  }

  public static getActiveLabelNameId(): string {
    return store.getState().labels.activeLabelNameId;
  }

  public static getActiveLabelType(): LabelType {
    return store.getState().labels.activeLabelType;
  }
  public static getActiveLabelTypeName(): string {
    const type =LabelsSelector.getActiveLabelType()
    return `label`+ type.charAt(0).toUpperCase()+type.slice(1).toLowerCase()+`s`;
  }

  public static getImagesData(): ImageData[] {
    return store.getState().labels.imagesData;
  }

  public static getActiveImageIndex(): number {
    return store.getState().labels.activeImageIndex;
  }

  public static getActiveImageData(): ImageData | null {
    const activeImageIndex: number | null =
      LabelsSelector.getActiveImageIndex();

    if (activeImageIndex === null) return null;
    return LabelsSelector.getImageDataByIndex(activeImageIndex);
  }

  public static getImageDataByIndex(index: number): ImageData {
    const imagesData: ImageData[] = LabelsSelector.getImagesData();
    return imagesData[index];
  }

  public static getImageDataById(image_id: string): ImageData {
    const imagesData: ImageData[] = LabelsSelector.getImagesData();
    return find(imagesData, { image_id });
  }

  public static getActiveLabelId(): string | null {
    return store.getState().labels.activeLabelId;
  }

  public static getHighlightedLabelId(): string | null {
    return store.getState().labels.highlightedLabelId;
  }

  public static getActiveRectLabel(): LabelRect | null {
    const activeLabelId: string | null = LabelsSelector.getActiveLabelId();

    if (activeLabelId === null) return null;

    return find(LabelsSelector.getActiveImageData().labelRects, {
      id: activeLabelId,
    });
  }

  public static getActivePointLabel(): LabelPoint | null {
    const activeLabelId: string | null = LabelsSelector.getActiveLabelId();

    if (activeLabelId === null) return null;

    return find(LabelsSelector.getActiveImageData().labelPoints, {
      id: activeLabelId,
    });
  }

  public static getActivePolygonLabel(): LabelPolygon | null {
    const activeLabelId: string | null = LabelsSelector.getActiveLabelId();

    if (activeLabelId === null) return null;

    return find(LabelsSelector.getActiveImageData().labelPolygons, {
      id: activeLabelId,
    });
  }
  public static getActivePolylineLabel(): LabelPolyline | null {
    const activeLabelId: string | null = LabelsSelector.getActiveLabelId();

    if (activeLabelId === null) return null;

    return find(LabelsSelector.getActiveImageData().labelPolylines, {
      id: activeLabelId,
    });
  }

  public static getActiveLineLabel(): LabelLine | null {
    const activeLabelId: string | null = LabelsSelector.getActiveLabelId();

    if (activeLabelId === null) return null;

    return find(LabelsSelector.getActiveImageData().labelLines, {
      id: activeLabelId,
    });
  }
}
