import React, { useEffect, useState } from "react";
import getDataFromApi from "../getDataFromApi";
import Loader from "../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import { Alert, Collapse } from "reactstrap";
import AiModelform from "./AiModelform";
import { getCokkie } from "../../views/login/Login";

export default function AiModals() {
  const modalsurl = `${window["ENV"].API_URL}/view-api/tasks/models`;
  const [data, setData] = React.useState([]);
  const [loading, setloading] = React.useState(true);
  const[collapse, setCollapse] = React.useState(false);
  const [alert, setAlert] = useState("");
  useEffect(() => {
    if(!alert)
    setloading(true);
    getDataFromApi(modalsurl, "get")
      .then((result) => {
        setData([...result.model_cards]);
        console.log([...result.model_cards]);
        localStorage.setItem("model", JSON.stringify(result.model_cards[0]._id))
        setloading(false);
      })
      .catch((e) => console.log(e.message));
  }, [alert]);

  useEffect(() => {
    if (alert) {
      let timeout = setTimeout(() => {
        setAlert();
      }, 3000);
      return () => {
        setAlert();
        clearTimeout(timeout);
      };
    }
  }, [alert]);
  function getform(id) {
   return  
  }
  function stop(x) {
    let cokkie = getCokkie("jwtToken");
    var myHeaders = new Headers();
    var requestOptions2 = {
      method: "delete",
      headers: myHeaders,
    };
    myHeaders.append("Authorization", `Bearer ${cokkie}`);
    fetch(
      `${window["ENV"].API_URL}/view-api/tasks/models/stop/${x}`,
      requestOptions2
    )
      .then((result) => result.json())
      .then((result) => {
        // console.log(result?.detail?.message)
        if(result)
        setAlert(x + " is Aborting");
      });

    // console.log(result.detail)
    //   } catch (error) {
    //     window.alert(error.message + " or Internal Server Error");
    //   }
  }
  function time_ago(time) {
    switch (typeof time) {
      case "number":
        break;
      case "string":
        time = +new Date(time);
        break;
      case "object":
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    var time_formats = [
      [60, "s", 1], // 60
      // [120, '1 minute ago', '1 minute from now'], // 60*2
      [3600, "M", 60], // 60*60, 60
      // [7200, '1 hour ago', '1 hour from now'], // 60*60*2
      [86400, "H", 3600], // 60*60*24, 60*60
      // [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
      [604800, "D", 86400], // 60*60*24*7, 60*60*24
      // [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
      [2419200, "W", 604800], // 60*60*24*7*4, 60*60*24*7
      // [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
      [29030400, "M", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      // [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
      [2903040000, "Y", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
      [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    var seconds = (+new Date() - time) / 1000,
      token = "",
      list_choice = 1;

    if (seconds == 0) {
      return "Just now";
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = "from now";
      list_choice = 2;
    }
    var i = 0,
      format;
    // eslint-disable-next-line no-cond-assign
    while ((format = time_formats[i++]))
      if (seconds < format[0]) {
        if (typeof format[2] == "string") return format[list_choice];
        else
          return (
            Math.floor(seconds / format[2]) + " " + format[1] + " " + token
          );
      }
    return time;
  }
  if (loading) return <Loader />;
  if (data)
    return (
      <>
        {/* <div>AiModals</div> */}
        <h2>Ai assist Models</h2>
       
        {alert && (
        <Alert
          color={alert?.status == "success" ? "success" : "danger"}
          className="mb-0 shadow d-flex mt-2"
          isOpen={alert?.message}
          style={{ width: "100%", borderRadius: 10 }}
        >
          {alert?.message? alert?.message:alert}
        </Alert>
      )}
        {data.map((x, i) => {
          return (
            <div className="card p-4 my-3 cursor-pointer ">
              <h4 className="d-flex align-items-center flex-wrap">
                {x.model_name}{" "}
                <span
                  style={{
                    width: 15,
                    height: 15,
                    display: "inline-block",
                    marginLeft: 8,
                    borderRadius: 8,
                  }}
                  className={x.status=="running" ? "bg-success" : "bg-danger"}
                ></span>{" "}
                <span
                  className="h6 mb-0 ms-2"
                  style={{ color: "rgb(165, 162, 162)" }}
                >
                  {" "}
                  {time_ago(x.last_used)} ago
                </span>
              </h4>
              <h6 className="text-gray" style={{ color: "rgb(165, 162, 162)" }}>
                {x.description}
              </h6>
              <div className="mt-2">
                <button
                  className="me-2 p-2 grey-card bg-light"
                  onClick={() => {
                    setCollapse(!collapse);
                  }}
                  disabled={x.running}
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={faPlay}
                    className="text-success"
                  ></FontAwesomeIcon>{" "}
                  Run
                </button>
                <button className="mx-2 p-2 grey-card" disabled={x.status=="running" ? false : true}    onClick={() => {
                    stop(x._id)
                  }}>
                  <FontAwesomeIcon
                    icon={faStop}
                    className="text-danger"
                  ></FontAwesomeIcon>{" "}
                  Stop
                </button>
              </div>
              <Collapse isOpen={collapse} className="mt-4">
               
                  <AiModelform id={x._id} collpase={(x) => {
                    setCollapse(!collapse); setAlert(x);
                  }}/>
              
              </Collapse>
            </div>

          );
        })}
      </>
    );
  return <>nodata</>;
}
