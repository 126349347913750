import React from "react";
import "./ErrorScreen.scss";
export default function ErrorScreen({ message, sub }) {
  const icon = (
    <div
      className="error_svg"
      style={{
        padding: "20px",
        margin: "10px 47%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <svg
        width="99"
        height="139"
        viewBox="0 0 99 139"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M96.968 120.798V49.46A47.434 47.434 0 0 0 49.534 2.026a47.331 47.331 0 0 0-16.289 2.872c-11.494 4.208-7.628 21.51 4.563 20.38.348-.032.703-.064 1.058-.084 23.027-1.484 42.388 17.09 42.626 40.13.549 46.355-.335 54.313-.335 54.313v9.19a7.897 7.897 0 0 0 7.906 7.899 7.906 7.906 0 0 0 7.905-7.899v-8.029z"
          fill="none"
        />
        <path
          d="M2.105 116.552V49.434A47.435 47.435 0 0 1 49.533 2v0a47.434 47.434 0 0 1 47.434 47.434v71.364"
          stroke="#A0AABB"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M20.59 61.412v31.43a9.254 9.254 0 0 1-18.51 0v-31.43"
          fill="none"
        />
        <path
          d="M20.59 61.412v31.43a9.254 9.254 0 0 1-9.255 9.254v0a9.257 9.257 0 0 1-9.255-9.255V61.412M78.459 61.412v31.43a9.254 9.254 0 0 0 9.248 9.254v0a9.254 9.254 0 0 0 9.255-9.255V61.412M41.57 72.848h15.934M34.115 45.233l3.685 3.685M34.115 48.918l3.685-3.685M61.273 45.233l3.684 3.685M61.273 48.918l3.684-3.685"
          stroke="#A0AABB"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M2.105 129.014a7.744 7.744 0 0 0 7.745 7.712h.387a7.746 7.746 0 0 0 7.744-7.712v-9.377"
          fill="none"
        />
        <path
          d="M2.105 129.014a7.744 7.744 0 0 0 7.745 7.712h.387a7.746 7.746 0 0 0 7.744-7.712v-9.377"
          stroke="#A0AABB"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M17.917 119.637v9.377a7.744 7.744 0 0 0 7.744 7.712h.388a7.746 7.746 0 0 0 7.744-7.712v-9.377"
          fill="none"
        />
        <path
          d="M17.917 119.637v9.377a7.744 7.744 0 0 0 7.744 7.712h.388a7.746 7.746 0 0 0 7.744-7.712v-9.377"
          stroke="#A0AABB"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M33.73 119.637v9.377a7.745 7.745 0 0 0 7.712 7.712h.38a7.748 7.748 0 0 0 7.712-7.712v-9.377"
          fill="none"
        />
        <path
          d="M33.73 119.637v9.377a7.745 7.745 0 0 0 7.712 7.712h.38a7.748 7.748 0 0 0 7.712-7.712v-9.377"
          stroke="#A0AABB"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M49.534 119.637v9.377a7.744 7.744 0 0 0 7.745 7.712h.387a7.748 7.748 0 0 0 7.712-7.712v-9.377"
          fill="none"
        />
        <path
          d="M49.534 119.637v9.377a7.744 7.744 0 0 0 7.745 7.712h.387a7.748 7.748 0 0 0 7.712-7.712v-9.377M81.157 119.637v9.377a7.744 7.744 0 0 0 7.745 7.712h.387A7.745 7.745 0 0 0 97 129.014v-9.377"
          stroke="#A0AABB"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </svg>
      <div
        className="mt-2"
        style={{
          width: "150px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: "#e4e8f1",
        }}
      ></div>
    </div>
  );

  return (
    <>
      <div className="d-flex flex-column">
        <div className="w-100">{icon}</div>
      </div>
      <div className="h5 d-flex justify-content-center">{message}</div>
      {!sub && (
        <div className="grey-sub d-flex justify-content-center text-center">
          Oops! Something went wrong on our end or no data. Please try again later. 
        </div>
      )}
    </>
  );
}
