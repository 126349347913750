import Select from "react-select";
function CustomSelect(props) {
  // console.log(props);
  const customStyles = {
    control:(provided, state) => ({
      ...provided,
      overflow: "visible",
      top: 0,
      padding:8,
      borderRadius:0,
      border: "1px solid #000",    
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      overflow: "visible",
      top: 0,
      border: "1px solid #eee",
      boxShadow: "0 0.5rem 1rem var(--shadow-color)",
      color: state.isSelected ? "#fff" : "#000",
      background: state.isSelected
        ? "#3755d3"
        : state.isFocused
          ? "#eee"
          : "#fff",
      cursor: "pointer",
      padding: 8,
    }),
  };
  // console.log(props);
  return (
    <div>
      {props?.schema?.title} {props.required ? "*" : ""}
      <Select
        isMulti={props?.schema?.type === "array"}
        styles={customStyles}
        maxMenuHeight={124}
        defaultValue={
          Array.isArray(props?.value)
            ? props?.options?.enumOptions.filter((v) =>
              props.value.includes(v.value)
            )
            : props?.options?.enumOptions.filter((v) =>
              String(props?.value)?.includes(v.value)
            )
        }
        options={props?.options?.enumOptions}
        placeholder={props?.placeholder}
        required={props?.required}
        isDisabled={props?.disabled}
        isOptionDisabled={() => props?.schema?.maxItems <= props?.value?.length}
        className={
          props?.uiSchema?.classNames + " react-select" + props?.isDisabled &&
          `select--is-disabled`
        }
        onChange={(e) => {
          return props.onChange(
            props?.schema?.type === "array" ? e.map((v) => v.value) : e.value
          );
        }}
      ></Select>
    </div>
  );
}
// function CustomRangeWidget(props) {
//   console.log(props);
//   <>
//   <Form.Control
//       id={props.id}
//       name={props?.id}
//       placeholder={props.placeholder}
//       autoFocus={props.autofocus}
//       required={props.required}
//       disabled={props.disabled}
//       readOnly={props.readonly}
//       type="range"
//       className={props.rawErrors?.length > 0 ? "is-invalid" : ""}
//       {...props.inputProps}
//       value={props.value || props.value === 0 ? props.value : ""}
//       onChange={props.onChangeOverride || props._onChange}
//       onBlur={props._onBlur}
//       onFocus={props._onFocus}
//       aria-describedby={props.ariaDescribedByIds}
//     />
//     {props.children}
//   </>
// }
export const CustomWidget = {
  SelectWidget: CustomSelect,
};
