import { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { ShapeLabel } from "./TimeSeriesAnnotation";

type Props = {
  labels: object;
  currentLabelData: ShapeLabel;
  setLabelData: any;
  prevLabel: any;
};
function ColorIndicator({ color }) {
  return (
    <span
      className="mx-1"
      style={{ width: 12, height: 12, borderRadius: 50, background: color }}
    />
  );
}
export default function LabelInput({
  labels,
  currentLabelData,
  setLabelData,
  prevLabel,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  function toggle() {
    setOpen((prev) => !prev);
  }
  function setLabel(label, color) {
    prevLabel.current = label;
    const temp = currentLabelData.shape;
    temp.line.color = color;
    temp.fillcolor = color + "80";
    setLabelData((prev) => {
      prev.find((f) => f.shape.x0 === temp.x0).label = label;
      return [...prev];
    });
  }
  return (
    <Dropdown isOpen={open} toggle={toggle} direction="down" className="w-75 ">
      <DropdownToggle
        className="text-capitalize d-flex align-items-center w-75"
        caret={false}
      >
        {currentLabelData.label ? (
          <>
            <ColorIndicator color={labels[currentLabelData.label]} />
            {currentLabelData.label}
          </>
        ) : (
          "Select Label"
        )}
      </DropdownToggle>
      <DropdownMenu className="w-100">
        {labels &&
          Object.keys(labels).map((v) => (
            <DropdownItem
              className="text-capitalize d-flex align-items-center"
              key={labels[v]}
              onClick={(e) => setLabel(v, labels[v])}
            >
              <ColorIndicator color={labels[v]} /> {v}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </Dropdown>
  );
}
